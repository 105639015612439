import theme from '../../../assets/styles/themes/Theme';

const { background } = theme.colors;

export default {
    divider: {
        backgroundColor: background,
        width: '100%',
        height: 1,
    },
};
