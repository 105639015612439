import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import styles from './styles';
import { ActivityIndicator, Touchable, View } from '../';

const Button = ({
    children,
    icon,
    backgroundColor,
    isTransparent,
    isFullWidth,
    isChildrenCentered,
    isLoading,
    style,
    childrenContainerStyle,
    ...rest
}) => {

    let buttonBackgroundColor = backgroundColor;
    let padding = styles.button.padding;
    let width = 'auto';
    let align= 'flex-start';

    if (isTransparent){
        buttonBackgroundColor = 'transparent';
        padding = 0;
    }
    if (isFullWidth){
        width = '100%';
    }
    if (isChildrenCentered){
        align = 'center';
    }

    return (
        <Touchable
            style={{
                ...styles.button,
                ...{
                    backgroundColor: buttonBackgroundColor,
                    padding: padding,
                    width
                },
                ...style,
            }}
            {...rest}>
            {icon && <View style={styles.iconContainer}>{icon}</View>}
            <View
                style={{
                ...styles.buttonChildrenContainer,
                ...{
                    width,
                    justifyContent: align,
                },
                ...childrenContainerStyle,
                }}>
                {isLoading ? (
                    <ActivityIndicator 
                        size={24}
                        color={theme.colors.secondary}/>
                ) : (
                    children
                )}
            </View>
        </Touchable>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
    icon: PropTypes.object,
    isTransparent: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isChildrenCentered: PropTypes.bool,
    isLoading: PropTypes.bool,
    style: PropTypes.object,
    childrenContainerStyle: PropTypes.object
}

Button.defaultProps = {
    backgroundColor: theme.colors.primary
}

export default Button;

