import * as React from 'react';
import PropTypes from 'prop-types';
import { View } from '../';

const Container = ({ children, style, ...rest }) => {
    return (
        <View style={style} {...rest}>
            {children}
        </View>
    );
};

Container.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
}

export default Container;
