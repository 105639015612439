import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Container, HorizontalCard, Text, View } from '../../elements';
import { formatCurrency } from '../../../utils/numberFormater';
import { formatDateFromString } from '../../../utils/dateFormater';
import Header from '../../common/Header/Header';

const OrderHistoryContent = ({ data }) => {

    if (!data || !data.getOrders || !data.getOrders.orders) return '';

    const localizedStatus = (status) => {
        switch (status){
            case "pending":
                return "Pendiente";
            case "processing":
                return "En preparación";
            case "shipped":
                return "En camino";
            case "complete":
                return "Completa";
            case "canceled":
                return "Cancelada";
            default:
                return "Pendiente";
        }
    }

    const statusColor = (status) => {
        switch (status){
            case "pending":
                return "#f523e3";
            case "processing":
                return "#f5a623";
            case "shipped":
                return "#2de8fe";
            case "complete":
                return "#4de105";
            case "canceled":
                return "#d0021b";
            default:
                return "#f523e3";
        }
    }

    return (
        <>
            <Header title="Mis órdenes"/>
            <main style={styles.rootContainer}>
                {data.getOrders.orders.map((order, index) => {
                    const { orderId, createdAt, cart: { product }, status } = order;
                    const statusName = localizedStatus(status);
                    const products = product.edges.length;
                    var totalPrice = 0;
                    product.edges.forEach(product => {
                        const { product: { price }, qty } = product.node;
                        totalPrice += qty * parseFloat(price);
                    });
                    return (
                        <HorizontalCard
                            key={index}
                            onClick={() => window.location.href = "/track-order/"+orderId}>
                            <Container style={styles.orderContainer}>
                                <View>
                                    <Text isSecondary>{formatDateFromString(createdAt)}</Text>
                                    <Text isBold>{`Número de orden: ${orderId}`}</Text>
                                    <Text isSecondary>{`${products} ${products > 1 ? 'productos' : 'producto'} - ${formatCurrency(totalPrice)}`}</Text>
                                </View>
                                <View style={styles.statusView}>
                                    <View  style={{
                                        ...styles.statusIndicator,
                                        ...{
                                            backgroundColor: statusColor(status)
                                        }
                                        }}/>
                                    <Text isCenter isBold isPrimary>
                                        {statusName}
                                    </Text>
                                </View>
                            </Container>
                        </HorizontalCard>
                    )
                })}
            </main>
        </>
    );
};

OrderHistoryContent.propTypes = {
    data: PropTypes.object,
};

OrderHistoryContent.defaultProps = {

};

export default OrderHistoryContent;

