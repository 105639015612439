import * as React from 'react'; 
import PropTypes from 'prop-types';
import styles from './styles';
import { Container, Text, Touchable, View } from '../../../elements';
import { SVGOrder } from '../../../../assets/resources/sixtynitePlay/icons/icons';

const OrderSummary = ({ orders, style }) => {

    return (
        <Touchable onClick={() => window.location.href = "/order-history/"} style={style}>
            <Container style={styles.rootContainer}>
                <View style={styles.numberContainer}>
                    <Text isSecondary isPrimary>{orders}</Text>
                </View>
                <View style={styles.orderContainer}>
                    <SVGOrder width="30" height="30" style={styles.orderSvg}/>
                </View>
            </Container>
        </Touchable>
        
    );
};

OrderSummary.propTypes = {
    orders: PropTypes.number,
    style: PropTypes.object
}

export default OrderSummary;
