export default({
    button: {
        padding: 10,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    buttonChildrenContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0
    },
    iconContainer: {
        marginRight: 5
    }
});
