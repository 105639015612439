import theme from '../../../assets/styles/themes/Theme';

const { card, error } = theme.colors;
const { primary: primaryGradient } = theme.gradients;

export default ({
    rootContainer: {
        flex: 1,
        paddingTop: 40,
        paddingBottom: 135,
        overflowX: 'hidden',
    },
    deliveryTimeContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: card
    },
    canceledTex: {
        color: error,
    },
    deliveryStepContainer: {
        paddingTop: 25,
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 15
    },
    orderContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 10,
        backgroundColor: card,
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)',
    },
    totalPriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    totalPriceText: {
        fontSize: 16,
    },
    placeOrderAgainText: {
        fontSize: 16,
        color: 'white',
    },
    orderButton: {
        background: primaryGradient,
        marginBottom: 10
    },
    orderAgainContainer: {
        padding: 15
    },
    confirmOrderAgainButton: {
        marginTop: 30,
        background: primaryGradient,
    },
    cancelOrderAgainButton: {
        marginTop: 10
    },
});
