import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import NotFound from '../containers/NotFound';
import AppStoreMobile from '../containers/AppStoreMobile';
import Store from '../containers/app/Store';
import Home from '../containers/app/Home';
import Checkout from '../containers/app/Checkout';
import TrackOrder from '../containers/app/TrackOrder';
import OrderHistory from '../containers/app/OrderHistory';
import '../assets/styles/App.scss';

const App = (props) => {
    console.log(props);

    React.useEffect(() => {
        document.addEventListener('dblclick', (event) => {
            event.preventDefault()
        }, { passive: false });
    });

    return(
        <BrowserRouter>
            <Layout>  
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/store/:hash" component={Store}/>
                    <Route exact path="/checkout" component={Checkout}/>
                    <Route exact path="/track-order/:orderid" component={TrackOrder}/>
                    <Route exact path="/order-history" component={OrderHistory}/>
                    <Route exact path="/download" component={AppStoreMobile}/>
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
};


export default App;

