export const formatCurrency = (
    amount
) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'MXN',
        currencyDisplay: 'symbol',
        //maximumFractionDigits:2, 
        //minimumFractionDigits:0,
    });
    return formatter.format(amount).replace("MX", "");
}