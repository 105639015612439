import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import reducer from './reducers';
import App from './routes/App';
import { tagManagerArgs } from './GTMHandler';
 
// initialize client graphql-hooks
import { ClientContext } from 'graphql-hooks';
import GraphqlClient from './graphql/client';

// initialize gtm
TagManager.initialize(tagManagerArgs);

const history = createBrowserHistory();
const preloadedState = window.__PRELOADED_STATE__;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, preloadedState, composeEnhancers());
// grphql api client
const initialState = window.__INITIAL_STATE__;
const client = GraphqlClient(preloadedState.config, history, initialState);
var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjoicmVmcmVzaCIsImlhdCI6MTYxNzc1NzUwNSwibmJmIjoxNjE3NzU3NTA1LCJqdGkiOiJlY2ZiNzgwNi01ZTdjLTRkMDQtYTQ3My1lMWVmMWE4NDQ0ZGYiLCJpZGVudGl0eSI6IndlYmNsaWVudCIsImV4cCI6MTYxNzc1NzUwNX0.k2JRGckbAj84i6cfo6wi-0c-GvBCfUpYerY34KD8PXM";

//console.log('__preloaded State__');
//console.log(preloadedState);

if(preloadedState.session != undefined){
    //console.log(preloadedState.session);
    if(preloadedState.session.jwtToken != undefined){
      token = preloadedState.session.jwtToken;
    }
}

client.setHeader('Authorization', `Bearer ${token}`);
// graphql-hooks delete initialState
delete window.__PRELOADED_STATE__;
delete window.__INITIAL_STATE__;

ReactDOM.hydrate(
    <ClientContext.Provider value={client}>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </ClientContext.Provider>,
    document.getElementById('app'),
);
