import theme from '../../../../assets/styles/themes/Theme';

const { card, background, primary } = theme.colors;

export default ({
    categoryContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    categoryItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        borderWidth: 0.3,
        margin: 0,
        backgroundColor: card,
        borderColor: background, 
    },
    categoryImage: {
        height: 70,
        width: 70,
        objectFit: 'contain'
    },
    categoryTitle: {
        fontSize: 14,
        marginTop: 10,
        transition: 'color 0.2s',
    },
    categorySelected: {
        position: 'absolute',
        height: 3,
        left: 0,
        bottom: 0,
        right: 0,
        transition: 'opacity 0.2s',
        backgroundColor: primary,
    }
});
