export default {
    card: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 10,
        borderWidth: 1,
    },
    coverImageContainer: {
        verticalAlign: 'top',
        marginTop: 4,
        width: 92,
        height: 70,
        marginRight: 15,
        backgroundColor: 'transparent',
    },
    coverImage: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        objectFit: 'contain',
    },
    cardBody: {
        verticalAlign: 'top',
        width: '100%',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    cardTitle: {
        fontWeight: 'bold',
    },
    cardSubtitle: {
        marginTop: 2,
        marginBottom: 10,
    },
};
