import theme from '../../../assets/styles/themes/Theme';

const { card, primary, error } = theme.colors;
const { primary: primaryGradient } = theme.gradients;

export default ({
    rootContainer: {
        flex: 1,
        height: '100%',
    },
    contentContainer: {
        position: 'relative',
        height: '100%',
        marginBottom: 69,
    },
    coverContainer: {
        width: '100%',
        backgroundColor: card
    },
    coverPhoto: {
        width: '80%',
        margin: 'auto'
    },
    headingContainer: {
        padding: 10,
        backgroundColor: card
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
    },
    price: {
        fontSize: 20,
        marginLeft: 10
    },
    description: {
        marginTop: 10,
    },
    addToBasketButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: card,
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        padding: 10,
        marginTop: 10,
        backgroundColor: card,
    },
    formTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textField: {
        marginTop: 10,
    },
    buttonGroupSection: {
        marginTop: 15,
    },
    buttonGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    formButton: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
        backgroundColor: 'transparent',
    },
    buttonIcon: {
        fill: primary
    },
    qtyText: {
        marginLeft: 25,
        marginRight: 25,
    },
    removeProductText: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: 2,
        marginTop: 15,
        color: error,
    },
    addToBasketButton: {
        alignItems: 'center',
        background: primaryGradient,
    },
    addToBasketButtonText: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: 2,
        color: 'white',
    },
    removeProductContainer: {
        padding: 15
    },
    confirmRemoveProductButton: {
        marginTop: 30,
        backgroundColor: error,
    },
    cancelRemoveProductButton: {
        marginTop: 10
    },
});
