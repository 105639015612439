import * as React from 'react';
import PropTypes from 'prop-types';
import { View } from '../';

const Touchable = ({children, onClick, ...rest}) => {

    return (
        <View className="touchable" {...rest} onClick={onClick && onClick}>{children}</View>
    );
};

Touchable.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func
}

export default Touchable;
