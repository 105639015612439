import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import styles from './styles';
import { Container, Touchable, Text, View } from '../';


const TabBar = ({ tabs, currentIndex, onTabClicked, style, ...rest }) => {

    if (!tabs) return 'no tabs';

    const { primary } = theme.colors;
    const refs = tabs.map(() => React.createRef(null));
    const tabBarRef = React.createRef(null);

    React.useEffect(() => {
        if (!refs || !refs[currentIndex] || !refs[currentIndex].current) return;
        tabBarRef.current.scrollLeft = refs[currentIndex].current.offsetLeft - ((tabBarRef.current.offsetWidth - refs[currentIndex].current.offsetWidth) / 2);
    }, [currentIndex, refs] );

    return (
        <Container 
            className="tab-bar"
            innerRef={tabBarRef}
            style={{
                ...styles.tabBarScrollviewContainer,
                ...style
            }}
            {...rest}>
            {tabs && tabs.map((tab, index) => {
                return (
                    <Touchable
                        innerRef={refs[index]}
                        style={styles.tabItem}
                        onClick={() => onTabClicked(index)}
                        key={index}>
                        <Text isPrimary={(index == currentIndex)}>{tab}</Text>
                        <View 
                            style={{
                                ...styles.tabSelected, 
                                ...{
                                    backgroundColor: primary,
                                    opacity: (index == currentIndex) ? 1 : 0
                                }
                            }}
                        />
                    </Touchable>
                );
            })}
        </Container>
    );
};

TabBar.propTypes = {
    tabs: PropTypes.node,
    currentIndex: PropTypes.number,
    onTabClicked: PropTypes.func,
    style: PropTypes.object
}

TabBar.defaultProps = {
    currentIndex: 0
}

export default TabBar;