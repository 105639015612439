import { capitalize } from './stringFormater'

const GREGORIAN_OFFSET = 122192928000000000;

export const formatDateFromString = (
    stringDate
) => {
    const date = new Date(stringDate);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return capitalize(date.toLocaleString('es-MX', dateOptions));
}

export const formatHourFromString = (
    stringDate
) => {
    const date = new Date(stringDate);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const dateFromUUID = (uuid_str) => {
    var int_time = timeFromUUID(uuid_str) - GREGORIAN_OFFSET,
    int_millisec = Math.floor( int_time / 10000 );
    return new Date( int_millisec );
};

export const timeFromUUID = (uuid_str) => {
    var uuid_arr = uuid_str.split( '-' ),
    time_str = [
        uuid_arr[ 2 ].substring( 1 ),
        uuid_arr[ 1 ],
        uuid_arr[ 0 ]
    ].join( '' );
    return parseInt( time_str, 16 );
};

export const hoursBetween = (olderDate, minorDate) => {
    const seconds = Math.abs(olderDate - minorDate) / 1000;
    const hours = Math.floor(seconds / 3600);
    return hours;
}