import React from 'react';
import { useParams } from "react-router-dom";

const InitialPage = () => {
    
    const { hash } = useParams();

    React.useEffect(() => {
        const property = {
            hash: hash,
            createdAt: new Date()
        }
        localStorage.setItem('property', JSON.stringify(property));
        window.location.href = "/";
    });
    
    return (
        <>
        </>
    );
};

export default InitialPage;

