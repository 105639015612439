import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Icon = ({
    isPrimary,
    color,
    ...rest
}) => {

    const { primary, text } = theme.colors;
    let iconColor = isPrimary ? primary : text;
    if (color) {
        iconColor = color;
    }

    return (
        <FontAwesomeIcon {...rest} color={iconColor} />
    );
};

Icon.propTypes = {
    isPrimary: PropTypes.bool,
    color: PropTypes.string
}

export default Icon;