import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Splash from '../../components/app/home/Splash/Splash';
import ContentHome from '../../components/app/home/ContentHome';
import InfoView from '../../components/common/InfoView/InfoView';
import { hoursBetween } from '../../utils/dateFormater';
// GraphQL 
import AuthorizationComponent from '../../graphql/auth/AuthorizationComponent';
import { ClientContext } from 'graphql-hooks';

const Home = ({ match, query, session }) => {
    const client = React.useContext(ClientContext);
    const [graphqlClient, setClient] = React.useState(client);
    const [storeData, setStoreData] = React.useState(null);
    const [sessionData, setSessionData] = React.useState(null);
    const [bearerToken, setBearerToken] = React.useState(null);
    //const [position, setPosition] = React.useState(null);
    var currentPath = match.path;
    var hash;
    if (typeof window !== 'undefined') {
        const property = JSON.parse(localStorage.getItem('property'));
        if (property){
            if (hoursBetween(new Date(), new Date(property.createdAt)) > 24){
                localStorage.removeItem("property");
            }else{
                hash = property.hash;
            }
        }
    }

    /*
    React.useEffect(() => {
        if (!position){
            getPosition();
        }
    },[]);

    function getPosition(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition(position);
            }, (error) => {
                setPosition(error);
            });
        } else {
            console.log("Sorry Not available!");
        }
    }*/

    const renderContent = () => {
        if (storeData && storeData.allDataStore){
            const data = storeData.allDataStore;
            if (data.storeData){
                return (<ContentHome graphqlClient={graphqlClient} sessionData={sessionData} storeData={storeData} />);
            }else if (data.error){
                return (
                    <InfoView
                        image="https://storage.googleapis.com/lite-play-sixtynite-com/local.png"
                        title="Estás fuera del rango de nuestro servicio"
                        subtitle="Entra al motel para poder llevarte tus productos favoritos."
                        action={{
                            name: "Volver a intenarlo",
                            function: function(){ 
                                setStoreData(null);
                            }
                        }}>
                    </InfoView>
                )
            }
        }
        return (<Splash/>);
    }

    return (
        <>
            <AuthorizationComponent
                currentPath={currentPath}
                query={query}
                queryOptions={{ 
                    variables: { 
                        hash: hash, 
                        sessionId: session.sessionId
                    } 
                }}
                setGraphQLClient={(graphqlClient) => setClient(graphqlClient)}
                graphqlClient={graphqlClient}
                setData={(storeData) => setStoreData(storeData)}
                data={storeData}
                setSessionData={(sessionData) => setSessionData(sessionData)}
                sessionData={sessionData}
                setBearerToken={(bearerToken) => setBearerToken(bearerToken)}
                bearerToken={bearerToken}
                session={session}
            />
            {renderContent()}
            {!hash && (
                <InfoView
                    showLogo
                    image="https://storage.googleapis.com/lite-play-sixtynite-com/qrImage.png"
                    title="Escanea el código QR"
                    subtitle="Escanea el código QR que se encuentra en tu habitación para acceder a nuestro menú.">
                </InfoView>
            )}
        </>
    );
};

Home.propTypes = {
    match: PropTypes.object,
    query: PropTypes.string,
    session: PropTypes.object
};

Home.defaultProps = {
    query: `
        query ($hash: String, $sessionId: String){
            allDataStore(hash: $hash){
                ... on StoreDataField{
                    storeData{
                        name
                        cover
                        email
                        roomFormatId
                        address{
                            street
                            externalNumber
                            suburb
                        }
                        payments{
                            edges{
                                node{
                                    paymentId
                                    name
                                }
                            }
                        }
                        store{
                            categories{
                                edges{
                                    node{
                                        categoryId
                                        name
                                        subcategories{
                                            edges{
                                                node{
                                                    subcategoryId
                                                    name
                                                    products{
                                                        edges{
                                                            node{
                                                                sku
                                                                name
                                                                shortDescription
                                                                description
                                                                price
                                                                images{
                                                                    images
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    success
                    error {
                        code
                        message
                    }
                }
            }
            shoppingCart(sessionId: $sessionId){
                ... on ShoppingCartField{
                    cart{
                        ordered
                        sessionId
                        product{
                            edges{
                                node{
                                    product{
                                        sku
                                        name
                                        price
                                    }
                                    comments
                                    qty
                                }
                            }
                        }
                    }
                }
            }
            getOrders(sessionId: $sessionId){
                ... on OrdersField{
                    orders{
                      orderId
                    }
                }
            }
        }
    `
};

const mapStateToProps = (state) => {
    return {
        session: state.session
    };
};

export default connect(mapStateToProps, null)(Home);

