export default ({
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  actionButtonText: {
    fontSize: 14,
  },
});
