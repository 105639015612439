import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../../assets/styles/themes/Theme';
import { Container, Text, Touchable } from '../../../elements';
import { SVGBurguer, SVGBeer, SVGHandcuff } from '../../../../assets/resources/sixtynitePlay/icons/icons'

const FixedCategories = ({ categories, onCategoryClicked, currentIndex, style, ...rest }) => {

    const width = (100 / categories.length) + "%";
    const { primary, text } = theme.colors;

    const renderIcon = (categoryId, index) => {
        switch (categoryId){
            case "Restaurante":
                return (
                    <SVGBurguer 
                        width="15"
                        height="15"
                        style={{
                            fill: (index == currentIndex) ? 'white' : text
                        }}/>
                );
            case "Bar":
                return (
                    <SVGBeer 
                        width="15"
                        height="15"
                        style={{
                            fill: (index == currentIndex) ? 'white' : text
                        }}/>
                );
            case "Sexshop":
                return (
                    <SVGHandcuff 
                        width="15"
                        height="15"
                        style={{
                            fill: (index == currentIndex) ? 'white' : text
                        }}/>
                );
            default:
                return undefined;
        }
    }

    return (
        <Container style={{...styles.categoryContainer, ...style}} {...rest}>
            {categories.map((category, index) => {
                const { categoryId, name } = category.node;
                return (
                    <Touchable 
                        key={categoryId} 
                        onClick={() => onCategoryClicked(index)} 
                        style={{ 
                            ...styles.categoryItem, 
                            ...{ 
                                width: width,
                                backgroundColor: (index == currentIndex) ? primary : 'transparent'
                            } 
                        }}>
                        {renderIcon(name, index)}
                        <Container>
                            <Text 
                                style={styles.categoryTitle} 
                                isWhite={(index == currentIndex)}>
                                {name}
                            </Text>
                        </Container>
                    </Touchable>
                );
            })}
        </Container>
    );
};

FixedCategories.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    onCategoryClicked: PropTypes.func,
    currentIndex: PropTypes.number,
    style: PropTypes.object,
}

FixedCategories.defaultProps = {
    categories: []
}

export default FixedCategories;
