import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'graphql-hooks';

const AuthorizationComponent = ({
    query,
    queryOptions,
    setGraphQLClient,
    graphqlClient,
    setData,
    data,
    setSessionData,
    sessionData,
    setBearerToken,
    bearerToken,
    session
}) => {

    var queryToken = `
        query{
            refresh{
                newToken
            }
        }
    `;
                      
    const { loading, error, data: dataRefresh } = useQuery(queryToken);
    if (loading) return "Loading ...";
    if (error) return "";

    var token = dataRefresh.refresh.newToken;
    graphqlClient.setHeader('Authorization', `Bearer ${token}`);

    var verifyToken = `
        query{
            verifyToken {
                ... on VerifyField {
                    success
                }
            }
        }
    `;

    const { loading: loaded, error: errorVerify, data: authData } = useQuery(verifyToken, { client: graphqlClient });

    if (loaded) return "Loading ...";
    if (errorVerify) return "";

    const querySession = `
        query QueryAuth($sessionId: String){
            clientLogin(sessionId: $sessionId){
                ... on ClientLoginField{
                    clientLogin{
                        sessionId
                        accessAt
                        createAt
                    }
                }
            }
        }
    `;

    if (authData != undefined) {
        if (authData.verifyToken.success != undefined) {
            var success = authData.verifyToken.success;
            if (success) {
                const { data: dataSession} = useQuery(querySession, { client: graphqlClient, variables: { sessionId: session.sessionId }});
                if (query){
                    const { data: dataQuery } = useQuery(query, { client: graphqlClient, ...queryOptions});
                    data = dataQuery;  
                    setData(data);
                }
                bearerToken = token;
                sessionData = dataSession;
                setBearerToken(bearerToken);
                setGraphQLClient(graphqlClient);
                setSessionData(sessionData);
            }
        }
    }

    return (
        <>
        </>
    );
};

AuthorizationComponent.propTypes = {
    query: PropTypes.string,
    queryOptions: PropTypes.object,
    isAvailable: PropTypes.bool,
    setStatus: PropTypes.func,
    setGraphQLClient: PropTypes.func,
    graphqlClient: PropTypes.object,
    setData: PropTypes.func,
    data: PropTypes.object,
    setSessionData: PropTypes.func,
    sessionData: PropTypes.object,
    setBearerToken: PropTypes.func,
    bearerToken: PropTypes.string,
    session: PropTypes.object
};

export default AuthorizationComponent;

