import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../assets/styles/themes/Theme';
import { View } from '..';

const CheckBox = ({
    value,
    tintColors,
    style,
}) => {

    const { selected, unselected } = tintColors;

    return (
        <View
            style={{
                ...styles.checkBox,
                ...{
                    backgroundColor: value ? selected : 'transparent',
                    borderColor: value ? selected : unselected
                },
                ...style,
            }}>
            
        </View>
    );
};

CheckBox.propTypes = {
    value: PropTypes.bool,
    tintColors: PropTypes.object,
    style: PropTypes.object,
};

CheckBox.defaultProps = {
    tintColors: { 
        selected: theme.colors.primary,
        unselected: theme.colors.secondaryText
    }
};

export default CheckBox;
