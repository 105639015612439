import theme from '../../../assets/styles/themes/Theme';

const { background, text } = theme.colors;

export default {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
        marginTop: 5,
        marginBottom: 5,
    },
    leftIcon: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
    },
    textField: {
        flex: 1,
        padding: 12,
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        color: text,
        backgroundColor: background,
    },
};
