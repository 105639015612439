import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, Container, Dialog, Image, Text, TextField, View } from '../../elements';
import { formatCurrency } from '../../../utils/numberFormater';
import { SVGMinus, SVGPlus } from '../../../assets/resources/sixtynitePlay/icons/icons';
import { useMutation } from 'graphql-hooks';
import { addToCartEvent, removeFromCartEvent } from '../../../GTMHandler';
import { onlyAlphanumeric } from '../../../utils/regex';

const ProductDetails = ({ 
    product, 
    qty, 
    comments, 
    propertyName,
    onMutationCompleted, 
    graphqlClient, 
    sessionData,
    addProductMutation, 
    removeProductMutation 
}) => {

    const { sku, name, description, price, images } = product;

    const defaultQty = qty ? qty : 1 ;
    const textFieldRef = React.useRef(null);

    const [totalQty, setTotalQty] = React.useState(defaultQty);
    const [isLoading, setLoading] = React.useState(false);
    const [isDialogVisible, setDialogVisible] = React.useState(false);

    React.useEffect(() => {
        if (qty){
            setTimeout(function(){
                textFieldRef.current.scrollIntoView();
            }, 10);
        }
    });

    function updateTotalProductQty(qty) {
        if (totalQty === 1 && qty < 1) {
            return;
        }
        setTotalQty(totalQty + qty);
    }

    const [addProductToCartMutation] = useMutation(addProductMutation);

    const onAddProducts =  async () => {
        if (!isLoading){
            setLoading(true);
            const { data } = await addProductToCartMutation({
                client: graphqlClient,
                variables: { 
                    comments: textFieldRef.current.value,
                    sku: sku,
                    qty: totalQty,
                    sessionId: sessionData.clientLogin.clientLogin.sessionId,
                },
            })
            if (data && 
                data.addToShoppingCart && 
                data.addToShoppingCart.clientCart && 
                data.addToShoppingCart.clientCart.cart && 
                data.addToShoppingCart.clientCart.cart.product && 
                data.addToShoppingCart.clientCart.cart.product.edges){
                setLoading(false);
                addToCartEvent(product, totalQty, propertyName);
                onMutationCompleted(data.addToShoppingCart.clientCart.cart.product.edges);
            }
        }
    }

    const [removeProductFromCartMutation] = useMutation(removeProductMutation);

    const onRemoveProduct =  async () => {
        const { data } = await removeProductFromCartMutation({
            client: graphqlClient,
            variables: { 
                sku: sku,
                sessionId: sessionData.clientLogin.clientLogin.sessionId,
            },
        })
        if (data && 
            data.removeProduct && 
            data.removeProduct.clientCart && 
            data.removeProduct.clientCart.cart && 
            data.removeProduct.clientCart.cart.product && 
            data.removeProduct.clientCart.cart.product.edges){
            removeFromCartEvent(product, propertyName);
            onMutationCompleted(data.removeProduct.clientCart.cart.product.edges)
        }
    }

    return (
        <>
            <Container>
                <View style={styles.contentContainer}>
                    <View style={styles.coverContainer}>
                        <Image
                            source={images.images[0]}
                            style={styles.coverPhoto}
                        />
                    </View>
                    <Container style={styles.headingContainer}>
                        <Container style={styles.titleContainer}>
                            <Text isBold style={styles.title}>{name}</Text>
                            <Text isBold isPrimary style={styles.price}>
                                {formatCurrency(price)}
                            </Text>
                        </Container>
                        <Text style={styles.description}>{description}</Text>
                    </Container>
                    <Container style={styles.formContainer}>
                        <Text style={styles.formTitle}>Instrucciones especiales</Text>
                        <TextField
                            maxLength="70"
                            innerRef={textFieldRef}
                            containerStyle={styles.textField}
                            onKeyPress={(event) => onlyAlphanumeric(event)}
                            placeholder="Agrega una nota"
                            defaultValue={comments && comments}
                        />
                        <Container style={styles.buttonGroupSection}>
                            <Container style={styles.buttonGroupContainer}>
                                <Button style={styles.formButton} onClick={() => updateTotalProductQty(-1)}>
                                    <SVGMinus height="35" width="35" style={styles.buttonIcon}/>
                                </Button>
                                <Text isHeadingTitle isBold style={styles.qtyText}>{totalQty}</Text>
                                <Button style={styles.formButton} onClick={() => updateTotalProductQty(1)}>
                                    <SVGPlus height="35" width="35" style={styles.buttonIcon}/>
                                </Button>
                            </Container>
                            {qty && (
                                <>
                                    <Button isTransparent onClick={() => setDialogVisible(true)}>
                                        <Text style={styles.removeProductText}>Eliminar producto</Text>
                                    </Button>
                                </>
                            )}
                        </Container>
                    </Container>
                </View>
                <View style={styles.addToBasketButtonContainer}>
                    <Button
                        isLoading={isLoading}
                        isFullWidth
                        isChildrenCentered
                        style={styles.addToBasketButton}
                        onClick={() => onAddProducts()}>
                        <Text style={styles.addToBasketButtonText}>
                            {(qty ? 'Actualizar carrito - ' : 'Agregar al carrito - ') + formatCurrency(parseFloat(price) * totalQty)}
                        </Text>
                    </Button>
                </View>
            </Container>
            <Dialog 
                isTitleCentered
                title="¿Eliminar producto?"
                isVisible={isDialogVisible}>
                    <View style={styles.removeProductContainer}>
                        <Text isCenter>Se eliminará el producto del carrito</Text>
                        <Button 
                        style={styles.confirmRemoveProductButton} 
                        onClick={() => onRemoveProduct()}>
                            <Text isWhite>Confirmar</Text>
                        </Button>
                        <Button 
                            isTransparent 
                            style={styles.cancelRemoveProductButton} 
                            onClick={() => setDialogVisible(false)}>
                            <Text>Cancelar</Text>
                        </Button>
                    </View>
            </Dialog>
        </>
    );
};

ProductDetails.propTypes = {
    product: PropTypes.object,
    qty: PropTypes.number,
    comments: PropTypes.string,
    propertyName: PropTypes.string,
    onMutationCompleted: PropTypes.func,
    graphqlClient: PropTypes.object,
    sessionData: PropTypes.object,
    addProductMutation: PropTypes.string,
    removeProductMutation: PropTypes.string,
}

ProductDetails.defaultProps = {
    addProductMutation: `
        mutation ($comments: String, $sku: String, $qty: Int, $sessionId: String){
            addToShoppingCart(comments: $comments, sku: $sku, qty: $qty, sessionId: $sessionId){
                clientCart{
                    ... on ShoppingCartField{
                        cart{
                            sessionId
                            product{
                                edges{
                                    node{
                                        qty
                                        comments
                                        product{
                                            sku
                                            name
                                            description
                                            price
                                            images{
                                                images
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    removeProductMutation: `
        mutation ($sku: String, $sessionId: String){
            removeProduct(sku: $sku, sessionId: $sessionId){
                clientCart{
                    ... on ShoppingCartField{
                        cart{
                            sessionId
                            product{
                                edges{
                                    node{
                                        qty
                                        comments
                                        product{
                                            sku
                                            name
                                            description
                                            price
                                            images{
                                                images
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
};

export default ProductDetails;

