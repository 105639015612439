import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <>
    <h1>404 Not Found</h1>
    <Link to="/">Regresa al Home</Link>
  </>
);

export default NotFound;
