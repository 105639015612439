const nativeAppLauncher = () => {
        var timers = [];
        var userAgent = window.navigator.userAgent;

        var checker = {
          iphone: userAgent.match(/(iPhone|iPod|iPad)/),
          mobile: userAgent.match(/Mobile/),
          android: userAgent.match(/Android/)
        };

        const isMobile = () => {
            console.log(userAgent);
            return checker.mobile;
        };

        const isAndroid = () => {
            return checker.android;
        };

        const isIphone = () => {
            return checker.iphone;
        };

        // Fetch the deep link into the Twitter app.
        const getDeepLink = () => {
                return isAndroid() ?
                    "sixtyniteapp://show/21bf3ee4-4221-4121-a8f8-2ea37ee1c617" :
                    "sixtyniteapp://show/21bf3ee4-4221-4121-a8f8-2ea37ee1c617";
        };

        const openApp = (deeplink, storeURI) => {
            var storeLaunched = false;
            var gotStoreURI = "string" == typeof storeURI;
            // If this handler is part of the UI thread, i.e. the `direct` result of a user action then
            // redirecting to the App Store will happen immediately. When not part of the UI thread however,
            // the redirect will bring up an Open in App dialog. Unless there is already a dialog showing,
            // in which case the redirect dialog will wait for the currently shown dialog to be dismissed.

            gotStoreURI && timers.push(window.setTimeout(function () {
                console.log('Launching App Store: ' + storeURI);
                storeLaunched = true;
                window.top.location = storeURI;
            }, 1000));

            isIphone() && timers.push(window.setTimeout(function () {
                console.log('Reloading page');
                storeLaunched && window.location.reload()
            }, 2000));

                window.location = deeplink;
        };

        const toLink = (href) => {
            window.location = href;
        }
        // Get the deep link URI to the Twitter app in the store appropriate for the OS.
        // Using a deep link guarantees that the app store opens even when using an alternate browser (e.g. Puffin or Firefox)
        const getStoreURI = () => {
            return isAndroid() ?
                    "https://play.google.com/store/apps/details?id=mx.kea.sixtynite&launch=true" :
                    "https://appsto.re/mx/LFHu6.i";
        };
        // Try to launch the native app on iOS/Android. Redirect to the app store if launch fails.
        const init = () => {
            if(isMobile()) {
                console.log("isMobile ...");
                openApp(getDeepLink(), getStoreURI());
            }else if (!isMobile()) {
                toLink("http://sixtynite.com");
            }
            return this;
        };

        return {
            init
        };
};

export default nativeAppLauncher;
