import theme from '../../../assets/styles/themes/Theme';

const { background, secondaryButton } = theme.colors;
const { secondary: secondaryGradient } = theme.gradients;

export default({
    rootContainer: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: background,
    },
    container:{
        display: 'flex',
        flexDirection: 'column',
        padding: 50,
        height: '70vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width:  '100%',
        margin: 'auto'
    },
    title: {
        marginTop: 10,
        color: secondaryButton
    },
    subtitle: {
        marginTop: 30,
    },
    actionButtonContainer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        right: 0,
        padding: 10
    },
    logo: {
        position: 'absolute',
        left: 20,
        right: 20,
        margin: 'auto'
    },
    actionButton: {
        background: secondaryGradient
    },
});
