import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { formatCurrency } from '../../../utils/numberFormater';
import { Button, Container, Divider, Dialog, HorizontalCard, Image, Section, TabBar, Text, TextField, View } from '../../elements';
import { SVGSearch } from '../../../assets/resources/sixtynitePlay/icons/icons';
import Header from '../../common/Header/Header';
import Categories from './Categories/Categories';
import FixedCategories from './FixedCategories/FixedCategories';
import ProductDetails from '../../common/ProductDetails/ProductDetails';
import CartSummary from './CartSummary/CartSummary';
import OrderSummary from './OrderSummary/OrderSummary';
import { onProductViewEvent, onPageViewEvent } from '../../../GTMHandler';
import { onlyAlphanumeric } from '../../../utils/regex';

const ContentHome = ({graphqlClient, sessionData, storeData }) => {

    if (!sessionData || !sessionData.clientLogin || !sessionData.clientLogin.clientLogin || !sessionData.clientLogin.clientLogin.sessionId) return '';
    if (!storeData || !storeData.allDataStore || !storeData.allDataStore.storeData || !storeData.allDataStore.storeData.store.categories || !storeData.allDataStore.storeData.store.categories.edges) return 'No categories';

    var products = [];
    if (storeData && storeData.shoppingCart && storeData.shoppingCart.cart && storeData.shoppingCart.cart.product && storeData.shoppingCart.cart.product.edges){
        products = storeData.shoppingCart.cart.product.edges;
    }

    const orders = storeData.getOrders && storeData.getOrders.orders;
    const categories = storeData.allDataStore.storeData.store.categories.edges;
    const allSubcategories = getAllSubcategories();

    const [currentCategoryIndex, setCurrentCategoryIndex] = React.useState(0);
    const [currentSubcategoryIndex, setCurrentSubcategoryIndex] = React.useState(0);
    const [tabBarOpacity, setTabBarOpacity] = React.useState(0);
    const [currentProduct, setCurrentProduct] = React.useState(null);
    const [cartProducts, setCartProducts] = React.useState(products);
    const [isSearchVisible, setSearchVisible] = React.useState(false);
    const [subcategories, setSubcategories] = React.useState(allSubcategories);
    const searchTextFieldRef = React.createRef(null);
    
    const headerOffset = 120;
    const { name: propertyName, address: { street, externalNumber, suburb } } = storeData.allDataStore.storeData;
    const propertyAddress = `${street} ${externalNumber}, ${suburb}`

    const subcategoryNames = categories && categories[currentCategoryIndex].node.subcategories.edges.map((subcategory) => subcategory.node.name);
    const subcategoryRefs = subcategoryNames.map(() => React.createRef(null));
    var isScrolling;

    if (typeof window !== 'undefined') {
        var property = JSON.parse(localStorage.getItem('property'));
        if (property){
            property.name = propertyName;
            if (property.roomData){
                property.roomData.roomFormatId = storeData.allDataStore.storeData.roomFormatId;
            }else{
                const roomData = {
                    roomFormatId: storeData.allDataStore.storeData.roomFormatId
                }
                property.roomData = roomData;
            }
            const payments = storeData.allDataStore.storeData.payments.edges.map((payment) => payment.node)
            property.payments = payments;
            localStorage.setItem('property', JSON.stringify(property));
        }
    }

    React.useEffect(() => {
        onPageViewEvent(propertyName);
    }, []);

    React.useEffect(() => {
        window.onscroll = () => {
            const scrollTop = window.pageYOffset + headerOffset;
            if (subcategoryRefs[0].current) {
                if (scrollTop < subcategoryRefs[0].current.offsetTop) {
                    setTabBarOpacity(0);
                } else {
                    setTabBarOpacity(1);
                }
            }
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                for (var i = 0; i < subcategoryRefs.length; i++) {
                    const currentRef = subcategoryRefs[i].current;
                    if (currentRef) {
                        if (scrollTop > currentRef.offsetTop && scrollTop < (currentRef.offsetTop + currentRef.offsetHeight)) {
                            setCurrentSubcategoryIndex(i);
                            return;
                        }
                    }
                }
            }, 66);
        }
    });

    React.useEffect(() => {
        if (isSearchVisible){
            searchTextFieldRef.current.focus();
        }
    }, [isSearchVisible] );

    function onSubcategorySelected(index) {
        window.scrollTo({
            top: subcategoryRefs[index].current.offsetTop - headerOffset,
            behavior: "smooth"
        });
        setCurrentSubcategoryIndex(index);
    }

    const renderProductDetails = () =>{
        var qty;
        var comments;
        if (cartProducts && cartProducts.length > 0){
            const findProduct = cartProducts.find(product => product.node.product.sku === currentProduct.node.sku);
            if (findProduct && findProduct.node){
                qty = findProduct.node.qty;
                comments = findProduct.node.comments;
            }
        }
        return (
            <ProductDetails 
                graphqlClient={graphqlClient} 
                sessionData={sessionData}
                product={currentProduct.node} 
                qty={qty}
                comments={comments}
                propertyName={propertyName}
                onMutationCompleted={(products) => {
                    setCartProducts(products);
                    setCurrentProduct(null);
                }}/>
        );
    }

    function onProductClick(product, list){
        onProductViewEvent(product.node, list, propertyName);
        setCurrentProduct(product);
    }

    const renderHeaderRight = () => {
        return (
            <Button
                onClick={() => { setSearchVisible(true) }}
                isTransparent>
                <SVGSearch 
                    width="20" 
                    height="20"
                    style={styles.headerRightElement}/>
            </Button>
        )
    }

    function getAllSubcategories(){
        var allSubcategories = [];
        categories.forEach(category => {
            category.node.subcategories.edges.forEach(subcategory => {
                allSubcategories.push(subcategory);
            })
        });
        return allSubcategories;
    }

    function searchProducts(e){
        if (!e || e === "" ){
            setSubcategories(allSubcategories);
        }else{
            var stringToSearch = e.toLowerCase();
            var subcategoriesSearched = [];
            allSubcategories.forEach(subcategory => {
                if (subcategory.node.name.toLowerCase().indexOf(stringToSearch) > -1){
                    subcategoriesSearched.push(subcategory);
                }else{
                    var productsSearched = subcategory.node.products.edges.filter(product => product.node.name.toLowerCase().indexOf(stringToSearch) > -1);
                    if (productsSearched && productsSearched.length > 0){
                        const subcategorySearched = {
                            node: {
                                name: subcategory.node.name,
                                subcategoryId: subcategory.node.subcategoryId,
                                products: {
                                    edges: productsSearched
                                },
                            }
                        }
                        subcategoriesSearched.push(subcategorySearched);
                    }
                }
            })
            setSubcategories(subcategoriesSearched);
        }
    }

    return (
        <>
            <Header 
                hidesBackButton
                title={storeData.allDataStore.storeData.name}
                rightElement={renderHeaderRight()}
                style={{
                    ...styles.header, 
                    ...{ opacity: tabBarOpacity }}
                }/>
            <TabBar
                tabs={subcategoryNames}
                currentIndex={currentSubcategoryIndex}
                style={{
                    ...styles.tabBar, 
                    ...{ opacity: tabBarOpacity }}}
                onTabClicked={(index) => {
                    onSubcategorySelected(index);
                }} />
            <FixedCategories
                categories={categories}
                style={{
                    ...styles.fixedCategories, 
                    ...{ opacity: tabBarOpacity }}}
                currentIndex={currentCategoryIndex}
                onCategoryClicked={(index) => {
                    setCurrentCategoryIndex(index);
                    onSubcategorySelected(0);
                    }}/>
            <main
                style={{
                    ...styles.rootContainer,
                    ...{
                        paddingBottom: (cartProducts && cartProducts.length > 0) ? 69 : 0
                    }
                    }}>
                <View style={styles.headingContainer}>
                    <Image
                        source={storeData.allDataStore.storeData.cover}
                        style={styles.coverPhoto}/>
                    <View
                        style={styles.infoHeadingContainer}> 
                        <Text isBold isWhite style={styles.title}>
                            {propertyName}
                        </Text>
                        <Text isWhite style={styles.subtitle}>
                            {propertyAddress}
                        </Text>
                    </View>
                </View>
                <View style={styles.menuContainer}>
                    <Text isBold isCenter isPrimary style={styles.menuLabel}>Nuestro menú</Text>
                </View>
                {categories &&
                    <Categories
                        categories={categories}
                        currentIndex={currentCategoryIndex}
                        onCategoryClicked={(index) => setCurrentCategoryIndex(index)} />
                }
                {categories && (
                    categories[currentCategoryIndex].node.subcategories.edges.map((subcategory, index) => {
                        const { subcategoryId, name, products } = subcategory.node;
                        return (
                            <Section
                                key={subcategoryId}
                                innerRef={subcategoryRefs[index]}
                                title={name}>
                                {products.edges.map((product, index) => {
                                    const { name, shortDescription, price, images } = product.node;
                                    return (
                                        <HorizontalCard
                                            key={index}
                                            coverImage={images.images[0]}
                                            title={name}
                                            subTitle={shortDescription}
                                            style={styles.product}
                                            onClick={() => onProductClick(product, "List")}>
                                            <Container >
                                                <Text isPrimary isBold>{formatCurrency(price)}</Text>
                                            </Container>
                                        </HorizontalCard>
                                    )
                                })}
                            </Section>
                        );
                    })
                )}
                { (orders && orders.length > 0) && (
                    <OrderSummary 
                        orders={orders.length} 
                        style={{
                            ...styles.orderSummary,
                            ...{
                                bottom: (cartProducts && cartProducts.length > 0) ? 79 : 10
                            }
                        }}/>
                )}
                <Dialog 
                    isFullSize
                    onClose={() => {
                        setSearchVisible(false);
                        setSubcategories(allSubcategories);
                    }} 
                    isVisible={isSearchVisible}>
                        <View style={{
                            ...styles.searchContainer,
                            ...{
                                paddingBottom: (cartProducts && cartProducts.length > 0) ? 69 : 0
                            }
                            }}>
                            <View style={styles.searchFieldContainer}>
                                <TextField
                                    maxLength="30"
                                    innerRef={searchTextFieldRef}
                                    style={styles.searchTextField}
                                    placeholder="Busca un producto"
                                    onKeyPress={(event) => onlyAlphanumeric(event)}
                                    onChange={(event) => searchProducts(event.target.value)}/>
                                <Divider/>
                            </View>
                            {subcategories && (
                                subcategories.map((subcategory) => {
                                    const { subcategoryId, name, products } = subcategory.node;
                                    return (
                                        <Section
                                            key={subcategoryId}
                                            title={name}>
                                            {products.edges.map((product, index) => {
                                                const { name, shortDescription, price, images } = product.node;
                                                return (
                                                    <HorizontalCard
                                                        key={index}
                                                        coverImage={images.images[0]}
                                                        title={name}
                                                        subTitle={shortDescription}
                                                        style={styles.product}
                                                        onClick={() => onProductClick(product, "Search result")}>
                                                        <Container >
                                                            <Text isPrimary isBold>{formatCurrency(price)}</Text>
                                                        </Container>
                                                    </HorizontalCard>
                                                )
                                            })}
                                        </Section>
                                    );
                                })
                            )}
                        </View>
                </Dialog>
                {(cartProducts && cartProducts.length > 0) && (
                    <CartSummary style={styles.cartSummary} products={cartProducts}/>  
                )}
                <Dialog 
                    isFullSize
                    onClose={() => setCurrentProduct(null)} 
                    isVisible={(currentProduct != null)}>
                    {currentProduct && renderProductDetails()}
                </Dialog>
            </main>
        </>
    );
};

ContentHome.propTypes = {
    graphqlClient: PropTypes.object, 
    sessionData: PropTypes.object,
    storeData: PropTypes.object,
};

export default ContentHome;

