import theme from '../../../../assets/styles/themes/Theme';

const { card, background } = theme.colors;

export default ({
    categoryContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: background,
    },
    categoryItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        margin: 0,
        borderColor: card, 
        height: 40
    },
    categoryTitle: {
        fontSize: 12,
        marginLeft: 5,
        transition: 'color 0.2s',
    }
});