export default({
    rootContainer: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
    },
    splashImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: '40vh',
        paddingBottom: 20
    },
    sixtynitePlayLogo: {
        width: '80%',
        marginBottom: 20
    },
});
