import React from 'react';
import AppStoreRedirect from '../components/app-store/AppStoreRedirect';

const AppStoreMobile = () => {
  return (
    <>
      <AppStoreRedirect/>
    </>
  );
};


export default AppStoreMobile;