import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { ActivityIndicator, Button, Container, Dialog, Text, View } from '../../../elements';
import { SVGSuccess, SVGError } from '../../../../assets/resources/sixtynitePlay/icons/icons';
import Theme from '../../../../assets/styles/themes/Theme';

const OrderModal = ({ 
    isModalVisible, 
    isLoadingOrder, 
    order, 
    onPrimaryAction, 
    onSecondaryAction 
}) => {

    return (
        <Dialog isVisible={isModalVisible}>
            <Container style={styles.container}>
                <View style={styles.content}>
                    <View style={styles.contentIcons}>
                        <ActivityIndicator 
                            style={{
                                ...styles.activityIndicator, 
                                ...{ opacity: isLoadingOrder ? 1 : 0 }
                            }}
                            color={Theme.colors.secondary}/>
                        <View style={{ opacity: isLoadingOrder ? 0 : 1 }}>
                            {order && (
                                <>
                                    <SVGSuccess 
                                        width="100" 
                                        height="100"
                                        style={{
                                            ...styles.successSvg,
                                            ...{ opacity: order.success  ? 1 : 0 }
                                        }}/>
                                    <SVGError 
                                        width="100" 
                                        height="100"
                                        style={{
                                            ...styles.errorSvg,
                                            ...{ opacity: order.success  ? 0 : 1 }
                                        }}/>
                                </>
                            )}
                        </View>
                    </View>
                    <View
                        style={{
                            ...styles.processingOrderContainer, 
                            ...{ display: isLoadingOrder ? "block" : "none" }
                        }}>
                        <Text isCenter isBold>Procesando tu orden...</Text>
                    </View>
                    <View
                        style={{
                            ...styles.successMessageContainer, 
                            ...{ opacity: isLoadingOrder ? 0 : 1 }
                        }}>
                        {order && (
                            <>
                                <Text isCenter isHeadingTitle isBold isPrimary style={styles.successMessage}>
                                    {order.success ? "Gracias por tu orden." : "Error al procesar tu orden"}
                                </Text>
                                <Text isCenter style={styles.successMessage}>
                                    {order.orderId ? `Número de orden: ${order.orderId}` : ""}
                                </Text>
                            </>
                        )}
                    </View>
                </View>
                <View
                    style={{
                        ...styles.footerButtonContainer, 
                        ...{ opacity: isLoadingOrder ? 0 : 1 }
                    }}>
                    {order && (
                        <>
                            <Button 
                                isFullWidth 
                                isChildrenCentered 
                                style={styles.trackOrderButton}
                                onClick={onPrimaryAction && onPrimaryAction}>
                                <Text isWhite isBold>
                                    {order.success ? "Ordenar otra cosa" : "Cancelar"}
                                </Text>
                            </Button>
                            <Button
                                isFullWidth
                                isTransparent
                                isChildrenCentered
                                style={styles.orderSomethingButton}
                                onClick={onSecondaryAction && onSecondaryAction}>
                                <Text>
                                    {order.success ? "Rastrear mi orden" : "Intentar de nuevo"}
                                </Text>
                            </Button>
                        </>
                    )}
                </View>
            </Container>
        </Dialog>
    );
};

OrderModal.propTypes = {
    isModalVisible: PropTypes.bool, 
    isLoadingOrder: PropTypes.bool, 
    order: PropTypes.object, 
    onPrimaryAction: PropTypes.func, 
    onSecondaryAction: PropTypes.func
};

export default OrderModal;