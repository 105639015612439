export default {
    tabBarScrollviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: 40,
        width: '100%',
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)'
    },
    tabItem: {
        position: 'relative',
        flex: '0 0 auto',
        alignItems: 'center',
        width: 'auto',
        height: '100%',
        textAlign: 'center',
        padding: 10
    },
    tabTitle: {
        fontSize: 14,
        transition: 'color 0.2s'
    },
    tabSelected: {
        position: 'absolute',
        height: 3,
        left: 0,
        bottom: -1,
        right: 0,
        transition: 'opacity 0.2s'
    }
};
