import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../assets/styles/themes/Theme';
import { Container, Text, View } from '../../elements';

const StepIndicator = ({ 
    labels,
    currentPosition,
    stepIndicatorSize,
    stepStrokeCurrentColor,
    separatorFinishedColor,
    separatorUnFinishedColor,
    stepIndicatorFinishedColor,
    stepIndicatorUnFinishedColor,
    stepIndicatorCurrentColor,
    stepIndicatorLabelFontSize,
    stepIndicatorLabelCurrentColor,
    stepIndicatorLabelFinishedColor,
    stepIndicatorLabelUnFinishedColor,
    labelColor,
    labelSize,
    currentStepLabelColor,
    separatorStrokeWidth,
    stepStrokeWidth
}) => {

    const separatorLeftMargin = (stepIndicatorSize - separatorStrokeWidth) / 2;
    var separatorFinishedBottom = (styles.stepIndicatorContainer.marginBottom + stepIndicatorSize) * 
                                    (labels.length - (currentPosition - 1)) - 
                                    styles.stepIndicatorContainer.marginBottom;

    return (
        <Container style={styles.container}>
            <View 
                style={{
                ...styles.separator,
                ...{
                    width: separatorStrokeWidth,
                    left: separatorLeftMargin,
                    backgroundColor: separatorUnFinishedColor,
                    bottom: stepIndicatorSize
                }}}/>
            <View 
                style={{
                ...styles.separator,
                ...{
                    width: separatorStrokeWidth,
                    left: separatorLeftMargin,
                    backgroundColor: separatorFinishedColor,
                    bottom: separatorFinishedBottom
                }}}/>
            {labels.map((label, index) => {
                const step = index + 1;
                const currentStep = step == currentPosition;
                const finishedStep = step < currentPosition;
                return(
                    <View 
                        key={index} 
                        style={{
                            ...styles.stepIndicatorContainer,
                            ...{ marginBottom: step == labels.length ? 
                                0 :
                                styles.stepIndicatorContainer.marginBottom }
                            }}>
                        <View 
                            style={{
                                ...styles.stepIndicator,
                                ...{
                                    width: stepIndicatorSize,
                                    height: stepIndicatorSize,
                                    borderWidth: stepStrokeWidth,
                                    backgroundColor: currentStep ? 
                                        stepIndicatorCurrentColor : 
                                        finishedStep ? stepIndicatorFinishedColor : stepIndicatorUnFinishedColor,
                                    borderColor: currentStep ? 
                                        stepStrokeCurrentColor : 
                                        finishedStep ? stepIndicatorFinishedColor : stepIndicatorUnFinishedColor,
                                }
                            }}>
                            <Text 
                                style={{
                                    fontSize: stepIndicatorLabelFontSize,
                                    color: currentStep ? 
                                        stepIndicatorLabelCurrentColor : 
                                        finishedStep ? stepIndicatorLabelFinishedColor : stepIndicatorLabelUnFinishedColor,
                                }}>
                                {step}
                            </Text>
                        </View>
                        <Text style={{
                            ...styles.label, 
                            ...{ 
                                fontSize: labelSize,
                                color: currentStep ? currentStepLabelColor : labelColor
                            }
                            }}>
                            {label}
                        </Text>
                    </View>
                );
            })}
        </Container>
    );
};

StepIndicator.propTypes = {
    labels: PropTypes.array,
    currentPosition: PropTypes.number,
    stepIndicatorSize: PropTypes.number,
    stepStrokeCurrentColor: PropTypes.string,
    separatorFinishedColor: PropTypes.string,
    separatorUnFinishedColor: PropTypes.string,
    stepIndicatorFinishedColor: PropTypes.string,
    stepIndicatorUnFinishedColor: PropTypes.string,
    stepIndicatorCurrentColor: PropTypes.string,
    stepIndicatorLabelFontSize: PropTypes.number,
    stepIndicatorLabelCurrentColor: PropTypes.string,
    stepIndicatorLabelFinishedColor: PropTypes.string,
    stepIndicatorLabelUnFinishedColor: PropTypes.string,
    labelColor: PropTypes.string,
    labelSize: PropTypes.number,
    currentStepLabelColor: PropTypes.string,
    separatorStrokeWidth: PropTypes.number,
    stepStrokeWidth: PropTypes.number
};

StepIndicator.defaultProps = {
    labels: [],
    currentPosition: 1,
    stepIndicatorSize: 35,
    stepStrokeCurrentColor: theme.colors.primary,
    separatorFinishedColor: theme.colors.primary,
    separatorUnFinishedColor: theme.colors.secondaryText,
    stepIndicatorFinishedColor: theme.colors.primary,
    stepIndicatorUnFinishedColor: theme.colors.card,
    stepIndicatorCurrentColor: theme.colors.card,
    stepIndicatorLabelFontSize: 12,
    stepIndicatorLabelCurrentColor: theme.colors.text,
    stepIndicatorLabelFinishedColor: theme.colors.card,
    stepIndicatorLabelUnFinishedColor: theme.colors.text,
    labelColor: theme.colors.text,
    labelSize: 12,
    currentStepLabelColor: theme.colors.primary,
    separatorStrokeWidth: 2,
    stepStrokeWidth: 5,
};

export default StepIndicator;

