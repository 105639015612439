import React from 'react';
import styles from './styles';
import { Container, Image, View } from '../../../elements';

const Splash = () => {

    return (
        <Container style={styles.rootContainer}>
            <Image 
                source="https://storage.googleapis.com/lite-play-sixtynite-com/splashImage.png"
                style={styles.splashImage}/>
            <View style={styles.container}>
                <Image 
                    source="https://storage.googleapis.com/lite-play-sixtynite-com/whiteSixtynitePlay.png"
                    style={styles.sixtynitePlayLogo}/>
                <View className="lds-ellipsis"><View></View><View></View><View></View><View></View></View>
            </View>
        </Container>
    );
};

export default Splash;