import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';

const Text = ({
    is,
    children,
    isPrimary,
    isSecondary,
    isWhite,
    isBold,
    isHeadingTitle,
    isCenter,
    hasMargin,
    isInline,
    style,
    ...rest
}) => {
    const { primary, secondaryText, text } = theme.colors;
    let color = text;
    let fontSize = 14;
    let marginTop = 0;
    let textAlign = 'left';
    let display = 'block'

    if (isSecondary) {
        color = secondaryText;
        fontSize = 12;
    }

    if (isHeadingTitle) {
        fontSize = 20;
    }

    if (isPrimary) {
        color = primary;
    }

    if (isWhite) {
        color = 'white';
    }

    if (isCenter) {
        textAlign = 'center';
    }

    if (hasMargin) {
        marginTop = 10;
    }

    if (isInline) {
        display = 'inline-block';
    }

    const fontWeight = isBold ? 'bold' : 'normal';

    const _renderSpan = () => {
        return (
            <span
                {...rest}
                style={{
                    ...{
                        color,
                        fontWeight,
                        fontSize,
                        textAlign,
                        marginTop,
                        display
                    },
                    ...style,
                }}>
                {children}
            </span>
        );
    };

    const _renderLabel = () => {
        return (
            <label
                {...rest}
                style={{
                    ...{
                        color,
                        fontWeight,
                        fontSize,
                        textAlign,
                        marginTop,
                        display
                    },
                    ...style,
                }}>
                {children}
            </label>
        );
    };

    const _renderP = () => {
        return (
            <p
                {...rest}
                style={{
                    ...{
                        color,
                        fontWeight,
                        fontSize,
                        textAlign,
                        marginTop,
                        display
                    },
                    ...style,
                }}>
                {children}
            </p>
        );
    };

    var Text;

    switch (is) {
        case 'span':
            Text = _renderSpan();
            break;
        case 'label':
            Text = _renderLabel();
            break;
        case 'p':
            Text = _renderP();
            break;
        default:
            Text = _renderSpan();
            break;
    }

    return Text;
};

Text.propTypes = {
    is: PropTypes.string,
    children: PropTypes.node,
    isPrimary: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isBold: PropTypes.bool,
    isHeadingTitle: PropTypes.bool,
    isCenter: PropTypes.bool,
    isWhite: PropTypes.bool,
    hasMargin: PropTypes.bool,
    style: PropTypes.object
}

export default Text;
