/**Initialize API Graphql
  NOTE: use can use any 'fetch' polyfill
  NOTE: a cache is required for SSR
*/
import memCache from 'graphql-hooks-memcache';
import { GraphQLClient } from 'graphql-hooks';

const GraphqlClient = (config, history, initialState ) => {
    try{
        var GRAPHQL_URI = "/graphql";
        var urlRoute = String(history.location.pathname);
        
        if(urlRoute === "/"){
            GRAPHQL_URI = config.urlGraphql.default;
        }else if(urlRoute.includes("b2b")){
            GRAPHQL_URI = config.urlGraphql.b2b;
        }else{
            GRAPHQL_URI = config.urlGraphql.default;
        } 
        // Step 1: Create the client importnside the request handler
        const client = new GraphQLClient({
            url: GRAPHQL_URI,
            cache: memCache({ initialState })
        });
        return client;
    }catch(error){
        console.log(error);
    }
};

export default GraphqlClient;

