import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
//import theme from '../../../assets/styles/themes/Theme';
import { Container, Dialog, Button, Text, View } from '../../elements';
import Header from '../../common/Header/Header';
import StepIndicator from '../../common/StepIndicator/StepIndicator';
import OrderSummary from '../checkout/OrderSummary/OrderSummary';
import OrderModal from '../checkout/OrderModal/OrderModal';
import { formatCurrency } from '../../../utils/numberFormater';
import { formatDateFromString, formatHourFromString } from '../../../utils/dateFormater';
import { useMutation } from 'graphql-hooks';
import { purchaseEvent } from '../../../GTMHandler';

const TrackOrderContent = ({ graphqlClient, sessionData, data, property, mutation }) => {

    if (!data || !data.getOrder || !data.getOrder.order) return '';

    const roomData = property.roomData;
    const hash = property.hash;
    const propertyName = property.name;

    const stepIndicatorLabels = [
        "Pendiente",
        "En preparación",
        "En camino",
        "Entregada",
    ]

    //const { primary, complementary, text, card } = theme.colors;

    const [newOrder, setNewOrder] = React.useState(null);
    const [isLoadingOrder, setLoadingOrder] = React.useState(true);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isDialogVisible, setDialogVisible] = React.useState(false);

    const order = data.getOrder.order;
    const orderStep = getStepByStatus(order.status);
    const cartProducts = order.cart.product.edges;
    var totalPrice = 0;
    var productsObj = [];
    cartProducts.forEach(product => {
        const { product: { name, sku, price, }, qty } = product.node;
        totalPrice += qty * parseFloat(price);
        const productObj = {
            name: name,
            id: sku,
            price: parseFloat(price).toFixed(2),
            quantity: qty,
            dimension1: propertyName
        }
        productsObj.push(productObj);
    });

    const [createOrdertMutation] = useMutation(mutation);

    const createOrder = async () => {
        setDialogVisible(false);
        if (roomData) {
            setModalVisible(true);
            const { data } = await createOrdertMutation({
                client: graphqlClient,
                variables: {
                    hash: hash,
                    orderId: order.orderId,
                    paymentMethod: roomData.paymentMethod,
                    placeId: roomData.placeId,
                    placeName: roomData.placeName,
                    sessionId: sessionData.clientLogin.clientLogin.sessionId,
                },
            })
            if (data && data.createOrder && data.createOrder.order){
                const orderObj = {
                    id: data.createOrder.order.orderId,                  
                    revenue: totalPrice,
                    option: roomData.paymentMethod == 0 ? 'cashondelivery' : 'card'
                }
                purchaseEvent(orderObj, productsObj);
                setNewOrder(data.createOrder.order);
            }
            setLoadingOrder(false);
        } 
    }

    const onModalPrimaryAction = () => {
        setModalVisible(false)
        if (newOrder.success){
            window.location.href = "/";
        }
    }

    const onModalSecondaryAction = () => {
        if (newOrder.success){
            window.location.href = "/track-order/"+newOrder.orderId.toString();
        } else {
            createOrder();
        }
    }

    function getStepByStatus(status){
        switch (status){
            case "pending":
                return 1;
            case "processing":
                return 2;
            case "shipped":
                return 3;
            case "complete":
                return 4;
            case "canceled":
                return 5;
            default:
                return 1;
        }
    }

    return (
        <>
            <Header title="Rastrear mi orden"/>
            <main style={styles.rootContainer}>
                <View style={styles.deliveryTimeContainer}>
                    <Text isCenter isSecondary>Orden número</Text>
                    <Text isCenter isPrimary isHeadingTitle isBold>
                        {order.orderId}
                    </Text>
                    <Text isCenter isSecondary>
                        {`${formatDateFromString(order.createdAt)}, ${formatHourFromString(order.createdAt)}`}
                    </Text>
                    { orderStep == 5 && (
                        <Text isCenter isBold style={styles.canceledTex}>
                            Cancelada
                        </Text>
                    )}
                </View>
                { (orderStep != 5) && (
                    <View style={styles.deliveryStepContainer}>
                        <StepIndicator 
                            /*stepStrokeCurrentColor={primary}
                            separatorFinishedColor={complementary}
                            stepIndicatorCurrentColor={text}
                            stepIndicatorFinishedColor={complementary}
                            stepIndicatorUnFinishedColor={text}
                            currentStepLabelColor={primary}
                            stepIndicatorLabelCurrentColor={card}
                            stepIndicatorLabelFinishedColor={card}
                            stepIndicatorLabelUnFinishedColor={card}*/
                            stepIndicatorSize={40} 
                            separatorStrokeWidth={2} 
                            labels={stepIndicatorLabels} 
                            currentPosition={orderStep}/>
                    </View>
                )}
                <OrderSummary 
                    cartProducts={cartProducts}/>
                <Container style={styles.orderContainer}>
                    <View style={styles.totalPriceContainer}>
                        <Text style={styles.totalPriceText}>Total</Text>
                        <Text isBold isPrimary style={styles.totalPriceText}>
                            {formatCurrency(totalPrice)}
                        </Text>
                    </View>
                    <Button
                        isFullWidth
                        isChildrenCentered
                        style={styles.orderButton}
                        onClick={() => window.location.href = "/"}>
                        <Text isBold isWhite style={styles.placeOrderAgainText}>
                            Ir a la tienda
                        </Text>
                    </Button>
                    <Button
                        isChildrenCentered
                        isFullWidth
                        isTransparent
                        onClick={() => setDialogVisible(true)}>
                        <Text isPrimary>
                            Ordenar de nuevo
                        </Text>
                    </Button>
                </Container>
                <OrderModal 
                    isModalVisible={isModalVisible}
                    isLoadingOrder={isLoadingOrder}
                    order={newOrder}
                    onPrimaryAction={() => onModalPrimaryAction()}
                    onSecondaryAction={() => onModalSecondaryAction()}/>
            </main>
            <Dialog 
                isTitleCentered
                title="¿Ordenar de nuevo?"
                isVisible={isDialogVisible}>
                    <View style={styles.orderAgainContainer}>
                        <Text isCenter>Se ordenarán los mismos productos</Text>
                        <Button 
                        style={styles.confirmOrderAgainButton} 
                        onClick={() => createOrder()}>
                            <Text isWhite>Confirmar</Text>
                        </Button>
                        <Button 
                            isTransparent 
                            style={styles.cancelOrderAgainButton} 
                            onClick={() => setDialogVisible(false)}>
                            <Text>Cancelar</Text>
                        </Button>
                    </View>
            </Dialog>
        </>
    );
};

TrackOrderContent.propTypes = {
    graphqlClient: PropTypes.object,
    sessionData: PropTypes.object,
    data: PropTypes.object,
    property: PropTypes.object,
    mutation: PropTypes.string,
};

TrackOrderContent.defaultProps = {
    mutation: `
        mutation($hash: String, $orderId: Int, $paymentMethod: Int, $placeId: String, $placeName: String, $sessionId: String){
            createOrder(hash: $hash, orderId: $orderId paymentMethod: $paymentMethod, placeId: $placeId, placeName: $placeName, sessionId: $sessionId){
                order{
                    ... on OrderCreatedField{
                        orderId
                        success
                    }
                }
            }
        }
    `
};

export default TrackOrderContent;

