export default {
    card: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.3)',
    },
    coverImageContainer: {
        height: 180,
        backgroundColor: 'transparent',
    },
    coverImageSmallContainer: {
        height: 120,
        backgroundColor: 'transparent',
    },
    coverImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        borderRadius: 10,
        resizeMode: 'cover',
    },
    cardBody: {
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    cardTitle: {
        marginTop: 5,
        fontWeight: 'bold',
    },
    cardSubtitle: {
        marginTop: 2,
        marginBottom: 10,
        fontSize: 12,
    },
};
