import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { View } from '../';


const Divider = ({ style }) => {
  return <View style={{...styles.divider, ...style}} />;
};

Divider.propTypes ={
    style: PropTypes.object
}

export default Divider;
