import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../assets/styles/themes/Theme';
import { View } from '../';

const ActivityIndicator = ({
    color,
    size,
    style,
    ...rest
}) => {

    return (
        <View 
            className="loader simple-circle"
            style={{
                ...styles.activityIndicator,
                ...{
                    border: `5px solid ${color}33`,
                    borderRightColor: color,
                    width: size,
                    height: size
                },
                ...style
            }}
            {...rest}/>
    );
};

ActivityIndicator.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.object,
}

ActivityIndicator.defaultProps = {
    color: theme.colors.primary,
    size: 70
}

export default ActivityIndicator;