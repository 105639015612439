import theme from '../../../assets/styles/themes/Theme';

const { card, background, primary } = theme.colors;

export default {
    header: {
        backgroundColor: card,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: 640,
        height: 40,
        padding: 10,
        position: 'fixed',
        zIndex: 5,
        top: 0,
        borderBottomWidth: 1,
        borderBottomColor: background
    },
    titleView: {
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10
    },
    title: {
        fontSize: 16
    },
    elementView:{
        width: 20,
        height: 20,
    },
    backButtonIcon:{
        width: 20,
        height: 20,
        fill: primary
    }
};
