import theme from '../../../../assets/styles/themes/Theme';

const { success, error } = theme.colors;
const { primary: primaryGradient } = theme.gradients;

export default ({
    container: {
        position: 'relative',
        height: 400,
        justifyContent: 'space-between',
    },
    processingOrderContainer: {
        marginTop: 10,
        transition: 'display 0.2s'
    },
    content: {
        width: '100%',
        padding: 30,
        alignItems: 'center',
        textAlign: 'center',
    },
    contentIcons: {
        position: 'relative',
        margin: 'auto',
        height: 100,
        width: 100
    },
    activityIndicator:{
        position: 'absolute',
        margin: 'auto',
        width: 100,
        height: 100,
        transition: 'opacity 0.2s'
    },
    successSvg: {
        position: 'absolute',
        margin: 'auto',
        fill: success,
        transition: 'opacity 0.2s'
    },
    errorSvg: {
        position: 'absolute',
        margin: 'auto',
        fill: error,
        transition: 'opacity 0.2s'
    },
    successMessageContainer: {
        alignItems: 'center',
        transition: 'opacity 0.2s'
    },
    successMessage: {
        marginTop: 10,
        display: 'block'
    },
    footerButtonContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: 15,
        paddingBottom: 30,
        transition: 'opacity 0.2s'
    },
    trackOrderButton: {
        background: primaryGradient
    },
    orderSomethingButton: {
        marginTop: 10,
    },
});