export function onlyAlphanumeric (e) {
    var regex = new RegExp('^[a-zA-Z0-9 ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
}

export function onlyAlpha (e) {
    var regex = new RegExp('^[A-Za-z ]+$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
}

export function onlyNumeric (e) {
    var regex = new RegExp('^[0-9]$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
}