import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import styles from './styles';
import { Container, Image, Touchable, Text } from '..';

const HorizontalCard = ({
    coverImage,
    title,
    subTitle,
    children,
    onClick,
    titleStyle,
    subTitleStyle,
    style,
    ...rest
}) => {

    const { card, background } = theme.colors

    const containerRef = React.useRef(null)

    const _renderCard = () => {
        return (
            <Container
                innerRef={containerRef}
                style={{
                    ...styles.card,
                    ...{ backgroundColor: card, borderColor: background },
                    ...style
                }}
                {...rest}>
                {coverImage && (
                    <Container
                        style={styles.coverImageContainer}>
                        <Image source={coverImage} style={styles.coverImage}/>
                    </Container>
                )}
                <Container style={{...styles.cardBody}}>
                    {title && (
                        <Text style={{...styles.cardTitle, ...titleStyle}}>
                            {title}
                        </Text>
                    )}
                    {subTitle && (
                        <Text isSecondary style={{...styles.cardSubtitle, ...subTitleStyle}}>
                            {subTitle}
                        </Text>
                    )}
                    {children}
                </Container>
            </Container>
        );
    };

    return onClick ? (
        <Touchable onClick={onClick}>{_renderCard()}</Touchable>
    ) : (
        _renderCard()
    );
};

HorizontalCard.propTypes = {
    coverImage: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.node,
    titleStyle: PropTypes.object,
    subTitleStyle: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func
}

export default HorizontalCard;
