import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Container, Image, Text, Touchable, View } from '../../../elements';

const Categories = ({ categories, onCategoryClicked, currentIndex, ...rest }) => {

    const width = (categories.length <= 3) ? (100 / categories.length) + "%" :  '25%';

    const iconFor = {
        "Restaurante": "https://storage.googleapis.com/lite-play-sixtynite-com/burguerIcon.png",
        "Bar": "https://storage.googleapis.com/lite-play-sixtynite-com/barIcon.png",
        "Sexshop": "https://storage.googleapis.com/lite-play-sixtynite-com/sexshopIcon.png",
    }

    return (
        <Container style={styles.categoryContainer} {...rest}>
            {categories.map((category, index) => {
                const { categoryId, name } = category.node;
                return (
                    <Touchable 
                        key={categoryId} 
                        onClick={() => onCategoryClicked(index)} 
                        style={{ 
                            ...styles.categoryItem, 
                            ...{ 
                                width: width
                            } 
                        }}>
                        <Container>
                            <Image source={iconFor[name]} style={styles.categoryImage}/>
                        </Container>
                        <Container>
                            <Text style={styles.categoryTitle} isBold isPrimary={(index == currentIndex)}>{name}</Text>
                        </Container>
                        <View 
                            style={{
                                ...styles.categorySelected, 
                                ...{
                                    opacity: (index == currentIndex) ? 1 : 0
                                }
                            }}
                        />
                    </Touchable>
                );
            })}
        </Container>
    );
};

Categories.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    onCategoryClicked: PropTypes.func,
    currentIndex: PropTypes.number,
}

Categories.defaultProps = {
    categories: []
}

export default Categories;
