import theme from '../../../assets/styles/themes/Theme';

const { primary, text, card, background } = theme.colors;

export default ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        transition: 'visibility 0s',
        backgroundColor: text+"CC",
        animation: 'fade-in 0.1s',
    },
    closeButton: {
        position: 'fixed',
        top: 10,
        left: 10,
        zIndex: 10
    },
    closeButtonIcon: {
        fill: primary
    },
    view: {
        margin: 15,
        paddingTop: 15,
        borderRadius: 10,
        width: '100%',
        backgroundColor: card,
    },
    fsview: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: background,
    },
    title: {
        padding: 15,
        paddingTop: 0,
    }
});
