import theme from '../../../../assets/styles/themes/Theme';

const { card } = theme.colors;
const { primary: primaryGradient } = theme.gradients;

export default({
    rootContainer: {
        position: 'relative'
    },
    numberContainer: {
        position: 'absolute',
        right: 0,
        bottom: 35,
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: card,
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.3)',
        zIndex: 1
    },
    orderContainer: {
        width: 48,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: primaryGradient,
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.3)',
    },
    orderSvg: {
        fill: card,
    },
});
