import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, Container, Dialog, RadioButton, Section, Text, TextField, View } from '../../elements';
import Header from '../../common/Header/Header';
import OrderSummary from './OrderSummary/OrderSummary';
import ProductDetails from '../../common/ProductDetails/ProductDetails';
import OrderModal from './OrderModal/OrderModal';
import { formatCurrency } from '../../../utils/numberFormater';
import { SVGCash, SVGCard} from '../../../assets/resources/sixtynitePlay/icons/icons';
import { useMutation } from 'graphql-hooks';
import { onReviewCartEvent, onProductViewEvent, purchaseEvent } from '../../../GTMHandler';
import { onlyAlphanumeric, onlyAlpha, onlyNumeric } from '../../../utils/regex';

const CheckoutContent = ({ graphqlClient, sessionData, data, property, mutation }) => {

    if (!data || !data.shoppingCart || !data.shoppingCart.cart || !data.shoppingCart.cart.product || !data.shoppingCart.cart.product.edges) return (<></>);
    
    const roomData = property.roomData;
    const hash = property.hash;
    const propertyName = property.name;
    const roomFormatId = property.roomData.roomFormatId;

    const history = useHistory();
    const radioButtonRigthElements = {
        0: <SVGCash height="30" style={styles.radioButtonIcon} />,
        1: <SVGCard height="30" style={styles.radioButtonIcon} />
    }

    const radioButtonItems = []
    property.payments.forEach(payment => {
        const { paymentId, name } = payment
        const item = {
            value: paymentId,
            label: name,
            rightElement: radioButtonRigthElements[paymentId]
        }
        radioButtonItems.push(item);
    });

    const textFieldRef = React.useRef(null);

    const [currentProduct, setCurrentProduct] = React.useState(null);
    const [cartProducts, setCartProducts] = React.useState(data.shoppingCart.cart.product.edges);
    const [paymentMethodValue, setPaymentMethodValue] = React.useState(0);
    const [order, setOrder] = React.useState(null);
    const [isLoadingOrder, setLoadingOrder] = React.useState(true);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [showTextFieldError, setShowTextFieldError] = React.useState(false);

    var totalPrice = 0;
    var productsObj = [];
    cartProducts.forEach(product => {
        const { product: { name, sku, price, }, qty } = product.node;
        totalPrice += qty * parseFloat(price);
        const productObj = {
            name: name,
            id: sku,
            price: parseFloat(price).toFixed(2),
            quantity: qty,
            dimension1: propertyName
        }
        productsObj.push(productObj);
    });

    React.useEffect(() => {
        onReviewCartEvent(productsObj);
    }, []);

    const [createOrdertMutation] = useMutation(mutation);

    const createOrder = async () => {
        if (!textFieldRef.current.value || textFieldRef.current.value == "" || textFieldRef.current.value == undefined || textFieldRef.current.value == null) {
            textFieldRef.current.focus();
            setShowTextFieldError(true);
        } else {
            setModalVisible(true);
            const { data } = await createOrdertMutation({
                client: graphqlClient,
                variables: {
                    hash: hash,
                    paymentMethod: paymentMethodValue,
                    placeId: 10,
                    placeName: textFieldRef.current.value,
                    sessionId: sessionData.clientLogin.clientLogin.sessionId,
                },
            })
            if (data && data.createOrder && data.createOrder.order){
                const roomDataToSave = {
                    roomFormatId: roomFormatId,
                    paymentMethod: paymentMethodValue,
                    placeId: 10,
                    placeName: textFieldRef.current.value
                }
                property.roomData = roomDataToSave;
                localStorage.setItem('property', JSON.stringify(property));
                const orderObj = {
                    id: data.createOrder.order.orderId,                  
                    revenue: totalPrice,
                    option: paymentMethodValue == 0 ? 'cashondelivery' : 'card'
                }
                purchaseEvent(orderObj, productsObj);
                setOrder(data.createOrder.order);
            }
            setLoadingOrder(false);
        }
    }

    const onModalPrimaryAction = () => {
        setModalVisible(false)
        if (order.success){
            window.location.href = "/";
        }
    }

    const onModalSecondaryAction = () => {
        if (order.success){
            window.location.href = "/track-order/"+order.orderId.toString();
        } else {
            createOrder();
        }
    }

    return (
        <>
            <Header 
                title="Información de la orden"/>
            <main
                style={{
                    ...styles.rootContainer,
                }}>
                <Section
                    title="Entregar en">
                    <Container style={styles.deliveryContainer}>
                        <Text>Habitación:</Text>
                        <TextField
                            maxLength="10"
                            type={roomFormatId == "1" ? 'tel' : 'text'}
                            onKeyPress={(event) => {
                                roomFormatId == "1" ? 
                                onlyNumeric(event) : 
                                roomFormatId == "2" ? 
                                onlyAlpha(event) :
                                onlyAlphanumeric(event)
                            }}
                            innerRef={textFieldRef}
                            style={styles.deliveryTextField}
                            placeholder="Nombre/número de tu habitación"
                            defaultValue={roomData ? roomData.placeName : undefined}
                            error={showTextFieldError}
                            onChange={() => setShowTextFieldError(false)}
                        />
                    </Container>
                </Section>
                <OrderSummary 
                    cartProducts={cartProducts} 
                    onProductSelected={(product) => {
                        onProductViewEvent(product.product, "Checkout", propertyName);
                        setCurrentProduct(product);
                    }}/>
                <Section
                    title="Método de pago">
                    <Container style={styles.paymentMethodContainer}>
                        <RadioButton
                            items={radioButtonItems}
                            defaultValue={paymentMethodValue}
                            onItemClicked={(item) => setPaymentMethodValue(item.value)} />
                    </Container>
                    <Container style={styles.paymentMethodLegend}>
                        <Text isSecondary >
                            Para pago con tarjeta se te llevará la terminal al entregar tu orden
                        </Text>
                    </Container>
                </Section>
                <Container style={styles.orderContainer}>
                    <View style={styles.totalPriceContainer}>
                        <Text style={styles.totalPriceText}>Total</Text>
                        <Text isBold isPrimary style={styles.totalPriceText}>
                            {formatCurrency(totalPrice)}
                        </Text>
                    </View>
                    <Button
                        isChildrenCentered
                        isFullWidth
                        style={styles.orderButton}
                        onClick={() => createOrder()}>
                        <Text isBold isWhite style={styles.placeOrderText}>
                            Ordenar
                        </Text>
                    </Button>
                </Container>
                <Dialog 
                    isFullSize 
                    onClose={() => setCurrentProduct(null)} 
                    isVisible={(currentProduct != null)}>
                    {currentProduct && (
                        <ProductDetails
                            graphqlClient={graphqlClient}
                            sessionData={sessionData}
                            product={currentProduct.product}
                            qty={currentProduct.qty}
                            comments={currentProduct.comments}
                            propertyName={propertyName}
                            onMutationCompleted={(products) => {
                                setCartProducts(products);
                                setCurrentProduct(null);
                                if (products && products.length == 0) {
                                    history.goBack();
                                }
                            }} />
                    )}
                </Dialog>
                <OrderModal 
                    isModalVisible={isModalVisible}
                    isLoadingOrder={isLoadingOrder}
                    order={order}
                    onPrimaryAction={() => onModalPrimaryAction()}
                    onSecondaryAction={() => onModalSecondaryAction()}/>
            </main>
        </>
    );
};

CheckoutContent.propTypes = {
    graphqlClient: PropTypes.object,
    sessionData: PropTypes.object,
    data: PropTypes.object,
    property: PropTypes.object,
    mutation: PropTypes.string
};

CheckoutContent.defaultProps = {
    mutation: `
        mutation($hash: String, $paymentMethod: Int, $placeId: String, $placeName: String, $sessionId: String){
            createOrder(hash: $hash, paymentMethod: $paymentMethod, placeId: $placeId, placeName: $placeName, sessionId: $sessionId){
                order{
                    ... on OrderCreatedField{
                        orderId
                        success
                    }
                }
            }
        }
    `
};

export default CheckoutContent;

