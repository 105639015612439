import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../assets/styles/themes/Theme'
import { CheckBox, Container, Text, View, Touchable } from '../../elements';

const RadioButton = ({
    items,
    onItemClicked,
    defaultValue,
    containerStyle
}) => {
    const {
        colors: { background },
    } = theme;
    const [selectedValue, setSelectedValue] = React.useState(null);

    const _onPress = (item) => {
        return () => {
            setSelectedValue(item.value);
            onItemClicked(item);
        };
    };

    return (
        <>
            {items.map((item) => {
                const { value, label, rightElement } = item;
                let isChecked = value === defaultValue;
                if (selectedValue) {
                    isChecked = value === selectedValue;
                }
                return (
                    <Container
                        key={value}
                        style={{
                            ...styles.container, 
                            ...{ borderBottomColor: background }, 
                            ...containerStyle
                        }}>
                        <Touchable style={styles.button} onClick={_onPress(item)}>
                            <Container style={styles.checkBoxContainer}>
                                <View>
                                    <CheckBox
                                        value={isChecked}
                                    />
                                </View>
                                <View>
                                    <Text is="label">{label}</Text>
                                </View>
                            </Container>
                            {rightElement && (
                                <Container style={styles.rightElementContainer}>
                                    {rightElement}
                                </Container>
                            )}
                        </Touchable>
                    </Container>
                );
            })}
        </>
    );
};

RadioButton.propTypes = {
    items: PropTypes.array,
    onItemClicked: PropTypes.func,
    defaultValue: PropTypes.number,
    containerStyle: PropTypes.object
};

RadioButton.defaultProps = {
    items: []
}

export default RadioButton;
