import theme from '../../../assets/styles/themes/Theme';

const { primary, card} = theme.colors;
const { primary: primaryGradient } = theme.gradients;

export default ({
    rootContainer: {
        flex: 1,
        paddingTop: 40,
        paddingBottom: 114,
        overflowX: 'hidden'
    },
    deliveryContainer: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: card,
    },
    deliveryTextField: {
        fontSize: 13,
        padding: 10,
        marginTop: 5
    },
    paymentMethodContainer: {
        backgroundColor: card
    },
    radioButtonIcon: {
        fill: primary
    },
    paymentMethodLegend: {
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10
    },
    orderContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 10,
        backgroundColor: card,
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)',
    },
    totalPriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    totalPriceText: {
        fontSize: 16,
    },
    placeOrderText: {
        fontSize: 16,
        color: 'white',
    },
    orderButton: {
        background: primaryGradient
    },
});
