import * as React from 'react';
import PropTypes from 'prop-types';

const Image = ({ source, style, innerRef, ...rest }) => {
    return (
        <img src={source} style={{...style}} ref={innerRef} {...rest}/>
    );
};

Image.propTypes = {
    source: PropTypes.string,
    style: PropTypes.object,
    innerRef: PropTypes.object
}

export default Image;

