import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import theme from '../../../assets/styles/themes/Theme';
import { View } from '../';

const TextField = ({
    leftIcon,
    leftIconSize,
    style,
    containerStyle,
    hasMargin,
    innerRef,
    error,
    ...rest
}) => {

    const { background, error: errorColor } = theme.colors;

    let margin = 0;
    if (hasMargin) {
        margin = 5;
    }

    return (
        <View
            style={{
                ...styles.container,
                ...{ marginTop: margin, marginBottom: margin },
                ...containerStyle,
            }}>
            {leftIcon && (
                <View style={styles.leftIcon} name={leftIcon} size={leftIconSize} />
            )}
            <input
                ref={innerRef}
                type="text"
                style={{
                    ...styles.textField,
                    ...{
                        borderColor: error ? errorColor : background 
                    },
                    ...style 
                }}
                onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                }}
                {...rest}
            />
        </View>
    );
};

TextField.propTypes = {
    leftIcon: PropTypes.string,
    leftIconSize: PropTypes.number,
    style: PropTypes.object,
    containerStyle: PropTypes.object,
    hasMargin: PropTypes.bool,
    innerRef: PropTypes.object,
    error: PropTypes.bool,
}

TextField.defaultProps = {
    leftIconSize: 14,
};

export default TextField;