export default ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    separator: {
        position: 'absolute',
        top: 0,
    },
    stepIndicatorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
        zIndex: 2
    },
    stepIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
    },
    label: {
        marginLeft: 10
    },
});
