import * as React from 'react';
import PropTypes from 'prop-types';

const View = ({ children, style, innerRef, ...rest }) => {
    return (
        <div style={{...style}} ref={innerRef} {...rest}>
            {children}
        </div>
    );
};

View.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    innerRef: PropTypes.object
}

export default View;
