export default ({
    rootContainer: {
        flex: 1,
        paddingTop: 40,
        overflowX: 'hidden',
    },
    orderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statusView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    statusIndicator: {
        width: 10,
        height: 10,
        borderRadius: 5,
        marginRight: 5
    }
});
