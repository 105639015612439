import theme from '../../../../assets/styles/themes/Theme';

const { primary } = theme.gradients
const { card } = theme.colors

export default{
    viewCartButtonContainer: {
        background: card,
        padding: 10,
    },
    viewCartButton: {
        background: primary
    },
    viewCartButtonChildren: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5,
        paddingRight: 5,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    viewCartButtonTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    viewCartButtonText: {
        fontSize: 16,
        padding: 2,
        marginRight: 10,
        color: 'white',
    },
    cardItemText: {
        color: 'white',
    },
    totalPriceText: {
        fontSize: 16,
        color: 'white',
    },
};
