import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Container, Divider, Text, Touchable, Section, View} from '../../../elements';
import { formatCurrency } from '../../../../utils/numberFormater';
import { SVGEdit } from '../../../../assets/resources/sixtynitePlay/icons/icons';

const OrderSummary = ({ cartProducts, onProductSelected, style }) => {

    if (!cartProducts) return(<></>);

    const renderProductSummary = (product) => {
        const { product: { name, price }, qty, comments } = product;
        const total = qty * parseFloat(price);
        return (
            <>
                <View style={styles.menuInfo}>
                    <View style={styles.view}>
                        <Text style={styles.productQuantityText}>
                            {qty}
                        </Text>
                        <View style={styles.productView}>
                            <Text isBold>{name}</Text>
                            { comments && (
                                <Text isSecondary>{comments}</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.view}>
                        <Text isBold>
                            {formatCurrency(total)}
                        </Text>
                        {onProductSelected && (
                            <SVGEdit style={styles.editIcon} height="20" width="20"/>
                        )}
                    </View>
                </View>
                <Divider/>
            </>
        );
    }

    return (
        <Section
            title="Resumen de la orden"
            style={style}>
            <Container style={styles.summaryContainer}>
                {cartProducts.map((cartProduct, cartProductIndex) => {
                    return(
                        onProductSelected ? (
                            <Touchable 
                                key={cartProductIndex}
                                onClick={() => onProductSelected(cartProduct.node)}>
                                {renderProductSummary(cartProduct.node)}
                            </Touchable>
                        ) : (
                            <View 
                                key={cartProductIndex}>
                                {renderProductSummary(cartProduct.node)}
                            </View>
                        )
                    );
                })}
            </Container>
        </Section>
    );
};

OrderSummary.propTypes = {
    cartProducts: PropTypes.array,
    onProductSelected: PropTypes.func,
    style: PropTypes.object
};


export default OrderSummary;
