import React, { useEffect } from 'react';
import nativeAppLauncher from '../../tools/nativeAppLauncher';

const AppStoreRedirect = () => {

    useEffect(() => {
        nativeAppLauncher().init();
    }, []);

    return(
        <>
            <div> Cargando... </div>
        </>
    );

};

export default AppStoreRedirect;
