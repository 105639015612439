import * as React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, Text, View } from '../../elements';
import { SVGLeftArrow } from '../../../assets/resources/sixtynitePlay/icons/icons';

const Header = ({ 
    title, 
    style, 
    hidesBackButton,
    rightElement
}) => {

    const history = useHistory();

    return (
        <header style={{ ...styles.header, ...style}}>
            <View style={styles.elementView}>
                { !hidesBackButton && (
                    <Button isTransparent onClick={() => history.goBack()}>
                        <SVGLeftArrow height="20" width="20" style={styles.backButtonIcon}/>
                    </Button>
                )}
            </View>
            { title && (
                <View style={styles.titleView}>
                    <Text isCenter isHeadingTitle isBold style={styles.title}>
                            {title}
                    </Text>
                </View>
            )}
            <View style={styles.elementView}>
                {rightElement}
            </View>
        </header>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    hidesBackButton: PropTypes.bool,
    rightElement: PropTypes.object,
}

export default Header;

