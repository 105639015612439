import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import styles from './styles';
import { Container, Image, Touchable, Text } from '../';

const Card = ({
    coverImage,
    title,
    subTitle,
    children,
    isSmallCover,
    onClick,
    titleStyle,
    subTitleStyle,
    style,
    ...rest
}) => {
    const card = theme.colors.card

    const _renderCard = () => {
        return (
            <Container
                style={{...{ backgroundColor: card }, ...styles.card, ...style}}
                {...rest}>
                {coverImage && (
                    <Container
                        style={
                            isSmallCover
                                ? styles.coverImageSmallContainer
                                : styles.coverImageContainer
                        }>
                        <Image source={coverImage} style={styles.coverImage}/>
                    </Container>
                )}
                <Container style={styles.cardBody}>
                    {title && (
                        <Text is="p" style={{...styles.cardTitle, ...titleStyle}}>
                            {title}
                        </Text>
                    )}
                    {subTitle && (
                        <Text is="p" style={{...styles.cardSubtitle, ...subTitleStyle}}>
                            {subTitle}
                        </Text>
                    )}
                    {children}
                </Container>
            </Container>
        );
    };

    return onClick ? (
        <Touchable onClick={onClick}>{_renderCard()}</Touchable>
    ) : (
        _renderCard()
    );
};

Card.propTypes = {
    coverImage: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.node,
    isSmallCover: PropTypes.bool,
    titleStyle: PropTypes.object,
    subTitleStyle: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func
}

export default Card;
