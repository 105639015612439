import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, Container, Image, Text, View} from '../../elements';

const InfoView = ({ image, title, subtitle, action, showLogo }) => {

    return (
        <Container style={styles.rootContainer}>
            <View style={styles.container}>
                {image && (
                    <Image source={image} style={styles.image}/>
                )}
                {title && (
                    <Text
                        style={styles.title} 
                        isHeadingTitle 
                        isBold
                        isCenter>
                        {title}
                    </Text>
                )}
                {subtitle && (
                    <Text isCenter style={styles.subtitle} >
                        {subtitle}
                    </Text>
                )}
            </View>
            {showLogo && (
                <Image 
                    source="https://storage.googleapis.com/lite-play-sixtynite-com/blackSixtynitePlay.png"
                    style={{
                        ...styles.logo,
                        bottom: action ? 80 : 20
                    }}/>   
            )}
            {action && (
                <Container style={styles.actionButtonContainer}>
                    <Button 
                        style={styles.actionButton} 
                        onClick={() => action.function()}>
                        <Text isWhite>{action.name}</Text>
                    </Button>
                </Container>
            )}
        </Container>
    );
};

InfoView.propTypes = {
    image: PropTypes.string, 
    title: PropTypes.string,
    subtitle: PropTypes.string,
    action: PropTypes.object,
    showLogo: PropTypes.bool,
};

export default InfoView;

