export const tagManagerArgs = {
    gtmId: 'P4ZKVR8'
}

export function onPageViewEvent(hotelName) {
    window.dataLayer.push({
        event: 'pageview',
        hotel: hotelName
    });
}

export function onProductViewEvent(product, list, dimension) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'productDetail',
        ecommerce: {
            detail: {
                actionField: {
                    list: list
                },
                products: [{
                    name: product.name,
                    id: product.sku,
                    price: parseFloat(product.price).toFixed(2),
                    dimension1: dimension
                }]
            }
        }
    });
}

export function addToCartEvent(product, qty, dimension) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
            currencyCode: 'MXN',
            add: {
                products: [{
                    name: product.name,
                    id: product.sku,
                    price: parseFloat(product.price).toFixed(2),
                    quantity: qty,
                    dimension1: dimension
                }]
            },
        }
    });
}

export function removeFromCartEvent(product, dimension) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products: [{
                    name: product.name,
                    id: product.sku,
                    price: parseFloat(product.price).toFixed(2),
                    dimension1: dimension
                }]
            },
        }
    });
}

export function onReviewCartEvent(products) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: 1,
                    option: 'Review Cart'
                },
                products: products
            }
        }
    });
}

export function purchaseEvent(order, products) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: order.id,
                    revenue: order.revenue,
                    option: order.option
                },
                products: products
            }
        }
    });
}