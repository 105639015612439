import theme from '../../../assets/styles/themes/Theme';

const { card, primary } = theme.colors;

export default({
    rootContainer: {
        flex: 1,
        overflowX: 'hidden'
    },
    header: {
        transition: 'opacity 0.2s',
    },
    headerRightElement: {
        fill: primary
    },
    fixedCategories: {
        position: 'fixed',
        zIndex: 2,
        marginTop: 40,
        transition: 'opacity 0.2s',
    },
    tabBar: {
        position: 'fixed',
        zIndex: 2,
        marginTop: 80,
        transition: 'opacity 0.2s',
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)',
        backgroundColor: card
    },
    headingContainer: {
        position: 'relative',
        overflow: 'hidden'
    },
    coverPhoto: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    infoHeadingContainer: {
        position: 'absolute',
        padding: 10,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)'
    },
    title: {
        marginTop: 30,
        fontSize: 20,
    },
    subtitle: {
        fontSize: 12,
    },
    menuContainer: {
        padding: 10
    },
    menuLabel: {
        fontSize: 16
    },
    product: {
        width: '100%',
        borderWidth: '0 0 1px 0',
    },
    container: {
        flex: 1,
    },
    tabText: {
        padding: 15,
        fontSize: 16,
        fontWeight: '500',
    },
    separator: {
        height: 0.5,
        width: '100%',
        alignSelf: 'flex-end',
    },
    sectionHeaderContainer: {
        height: 10,
        borderBottomWidth: 1,
    },
    sectionHeaderText: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 10,
        textAlign: 'left',
    },
    itemContainer: {
        display: 'flex',
        paddingVertical: 20,
        paddingHorizontal: 15,
        flexDirection: 'row',
    },
    itemTitle: {
        flex: 1,
        fontSize: 20,
    },
    itemPrice: {
        fontSize: 18,
    },
    itemDescription: {
        marginTop: 10,
        fontSize: 16,
    },
    itemRow: {
        flexDirection: 'row',
    },
    orderSummary: {
        position: 'fixed',
        right: 10,
        bottom: 79,
    },
    cartSummary:{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        boxShadow: '0px 1px 9px 0px rgba(0,0,0,0.5)',
    },
    searchContainer: {
        paddingTop: 56
    },
    searchFieldContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: card,
    },
    searchTextField: {
        fontSize: 14,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 45,
        padding: 9
    },
    searchIcon: {
        fill: primary
    }
});
