import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TrackOrderContent from '../../components/app/trackOrder/TrackOrderContent';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { hoursBetween } from '../../utils/dateFormater';
// GraphQL 
import AuthorizationComponent from '../../graphql/auth/AuthorizationComponent';
import { ClientContext } from 'graphql-hooks';

const TrackOrder = ({ match, query, session }) => {
    const client = useContext(ClientContext);
    const [graphqlClient, setClient] = React.useState(client);
    const [data, setData] = React.useState(null);
    const [sessionData, setSessionData] = React.useState(null);
    const [bearerToken, setBearerToken] = React.useState(null);
    var currentPath = match.path;
    const { orderid } = useParams();

    var property;
    if (typeof window !== 'undefined') {
        const propertyData = JSON.parse(localStorage.getItem('property'));
        if (propertyData){
            property = propertyData;
            if (hoursBetween(new Date(), new Date(property.createdAt)) > 24){
                localStorage.removeItem("property");
                window.location.href = "/";
            }
        }else{
            window.location.href = "/";
        }
    }

    return (
        <>
            <AuthorizationComponent
                currentPath={currentPath}
                query={query}
                queryOptions={{variables: { sessionId: session.sessionId, orderId: parseInt(orderid) }}}
                setGraphQLClient={(graphqlClient) => setClient(graphqlClient)}
                graphqlClient={graphqlClient}
                setData={(data) => setData(data)}
                data={data}
                setSessionData={(sessionData) => setSessionData(sessionData)}
                sessionData={sessionData}
                setBearerToken={(bearerToken) => setBearerToken(bearerToken)}
                bearerToken={bearerToken}
                session={session}
            />
            <TrackOrderContent graphqlClient={graphqlClient} sessionData={sessionData} data={data} property={property}/>
        </>
    );
};

TrackOrder.propTypes = {
    match: PropTypes.object,
    query: PropTypes.string,
    session: PropTypes.object
};

TrackOrder.defaultProps = {
    query: `
        query($sessionId: String, $orderId: Int){
            getOrder(sessionId: $sessionId, orderId: $orderId){
                ... on OrderField{
                    order{
                        orderId
                        status
                        createdAt
                        cart{
                            ordered
                            sessionId
                            product{
                                edges{
                                    node{
                                        product{
                                            sku
                                            name
                                            price
                                        }
                                        qty,
                                        comments
                                    }
                                }
                            }
                        }
                    }
                }
            }  
        }  
    `
};

const mapStateToProps = (state) => {
    return {
        session: state.session
    };
};

export default connect(mapStateToProps, null)(TrackOrder);

