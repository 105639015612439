export default ({
    colors: {
        primary: "#4226b8", //bluePurple
        secondary: "#50fee3", //lightGreen
        complementary: "#370576", //purple
        text: "#58595B", //darkGray
        secondaryText: "#9B9B9B", //dustyGray
        secondaryButton: "#B51AEB", //pink
        card: "#FFFFFF", //white
        background: "#EDEDED", // "#DDDDDD", //lightGray
        error: "#D25C47", // red,
        success: "#5ECD9F", // green
    },
    gradients: {
        primary: `linear-gradient(90deg, rgba(55,5,118,1) 0%, rgba(54,75,184,1) 100%)`,
        secondary: `linear-gradient(90deg, rgba(179,53,101,1) 0%, rgba(56,5,118,1) 100%)`
    }
});
