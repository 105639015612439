import theme from '../../../../assets/styles/themes/Theme'

const { card, primary } = theme.colors;

export default {
    summaryContainer: {
        backgroundColor: card
    },
    menuInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    view: {
        display: 'flex',
        flexDirection: 'row',
    },
    productQuantityText: {
        marginRight: 10,
    },
    productView: {
        marginRight: 10
    },
    editIcon: {
        fill: primary,
        marginLeft: 10,
    }
};
