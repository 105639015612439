import React from 'react';
import PropTypes from 'prop-types';

export const SVGMinus = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style} {...rest} >
            <g>
                <g>
                    <path d="M256,0C114.853,0,0,114.833,0,256s114.853,256,256,256c141.167,0,256-114.833,256-256S397.147,0,256,0z M256,472.341 c-119.295,0-216.341-97.046-216.341-216.341S136.705,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M355.148,234.386H156.852c-10.946,0-19.83,8.884-19.83,19.83s8.884,19.83,19.83,19.83h198.296 c10.946,0,19.83-8.884,19.83-19.83S366.094,234.386,355.148,234.386z" />
                </g>
            </g>
        </svg>
    );
};

export const SVGPlus = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style} {...rest} >
            <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256  256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0" />
            <path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
            <path d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0" />
        </svg>
    );
};

export const SVGClose = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style} {...rest} >
            <g>
                <g>
                    <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005 s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874 C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0 c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0 C493.435,187.359,493.435,324.651,409.08,409.006z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046 c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02 c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046 c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111 c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z" />
                </g>
            </g>
        </svg>
    );
};

export const SVGCash = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 407.534 407.534" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <g>
                <g>
                    <g>
                        <path d="M406.962,226.838c-0.001-0.002-0.001-0.003-0.002-0.005l-76.944-193.2c-1.633-4.105-6.286-6.109-10.391-4.476
                            c-0.003,0.001-0.006,0.002-0.009,0.004L7.464,153.457c-1.273,0.55-2.377,1.428-3.2,2.544C1.668,157.35,0.028,160.02,0,162.945
                            v208c0,4.418,3.582,8,8,8h336c4.418,0,8-3.582,8-8v-113.6l50.496-20.112C406.6,235.596,408.599,230.942,406.962,226.838z
                            M336,362.945H16v-192h320V362.945z M133.48,154.985l133.952-53.376c11.955,12.566,28.52,19.708,45.864,19.776l13.36,33.6H133.48
                            z M352,240.161v-77.216c0-4.418-3.582-8-8-8h-0.128l-16.952-42.576c-0.188-0.355-0.407-0.693-0.656-1.008
                            c-0.858-3.853-4.426-6.482-8.36-6.16c-16.138,1.471-31.925-5.327-41.944-18.064c-2.493-3.077-6.875-3.842-10.264-1.792
                            c-0.38,0.045-0.757,0.115-1.128,0.208l-163.536,65.12c-1.979,0.776-3.562,2.315-4.392,4.272H46.976l271.136-107.96l71.032,178.36
                            L352,240.161z"/>
                        <path d="M37.712,308.937c15.532,4.591,27.686,16.73,32.296,32.256c1.011,3.389,4.128,5.711,7.664,5.712
                            c0.415-0.026,0.827-0.088,1.232-0.184c0.359,0.102,0.726,0.177,1.096,0.224h192c0.33-0.043,0.656-0.11,0.976-0.2
                            c3.997,0.972,8.025-1.479,8.998-5.476c0.003-0.015,0.007-0.029,0.01-0.044c4.598-15.542,16.754-27.698,32.296-32.296
                            c3.769-1.152,6.111-4.908,5.488-8.8c0.107-0.388,0.184-0.784,0.232-1.184v-64c-0.047-0.401-0.127-0.797-0.24-1.184
                            c0.627-3.893-1.716-7.651-5.488-8.8c-15.542-4.597-27.695-16.758-32.28-32.304c-1.158-3.795-4.951-6.143-8.864-5.488
                            c-0.37-0.103-0.747-0.178-1.128-0.224H80c-0.403,0.048-0.802,0.128-1.192,0.24c-3.891-0.62-7.645,1.721-8.8,5.488
                            c-4.601,15.539-16.756,27.691-32.296,32.288c-3.769,1.152-6.111,4.908-5.488,8.8c-0.104,0.389-0.179,0.784-0.224,1.184v64
                            c0.046,0.376,0.121,0.748,0.224,1.112C31.568,303.974,33.915,307.772,37.712,308.937z M48,238.193
                            c16.064-6.452,28.796-19.184,35.248-35.248h185.496c6.455,16.064,19.19,28.796,35.256,35.248v57.504
                            c-16.059,6.459-28.789,19.189-35.248,35.248H83.248C76.789,314.886,64.059,302.156,48,295.697V238.193z"/>
                        <path d="M176,314.945c26.51,0,48-21.49,48-48c0-26.51-21.49-48-48-48c-26.51,0-48,21.49-48,48
                            C128.026,293.444,149.501,314.919,176,314.945z M176,234.945c17.673,0,32,14.327,32,32s-14.327,32-32,32s-32-14.327-32-32
                            S158.327,234.945,176,234.945z"/>
                        <path d="M88,278.945c6.627,0,12-5.373,12-12c0-6.627-5.373-12-12-12s-12,5.373-12,12C76,273.573,81.373,278.945,88,278.945z
                            M88,262.945c2.209,0,4,1.791,4,4c0,2.209-1.791,4-4,4c-2.209,0-4-1.791-4-4C84,264.736,85.791,262.945,88,262.945z"/>
                        <path d="M264,278.945c6.627,0,12-5.373,12-12c0-6.627-5.373-12-12-12c-6.627,0-12,5.373-12,12
                            C252,273.573,257.373,278.945,264,278.945z M264,262.945c2.209,0,4,1.791,4,4c0,2.209-1.791,4-4,4c-2.209,0-4-1.791-4-4
                            C260,264.736,261.791,262.945,264,262.945z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const SVGCard = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <g>
                <g>
                    <g>
                        <path d="M477.128,172.606l-53.68-139.568C413.276,6.654,383.641-6.488,357.257,3.684c-0.383,0.148-0.765,0.3-1.145,0.457
                            l-268.256,111.2c-25.543,10.478-38.097,39.391-28.312,65.208l7.376,19.688H50c-27.601,0.031-49.969,22.399-50,50v180
                            c0.031,27.601,22.399,49.969,50,50h340c27.601-0.031,49.969-22.399,50-50v-180c-0.016-2.714-0.257-5.422-0.72-8.096l9.288-3.76
                            C474.339,227.818,487.005,198.648,477.128,172.606z M50,216.238h340c17.956,0.051,32.768,14.074,33.8,32H16.2
                            C17.232,230.311,32.044,216.288,50,216.238z M130.408,168.046l53.472-26.744l10.992,27.496L141.4,195.534L130.408,168.046z
                            M424,430.238c-0.022,18.769-15.231,33.978-34,34H50c-18.769-0.022-33.978-15.231-34-34v-102h408V430.238z M424,312.238H16v-48
                            h408V312.238z M442.568,223.598l-8.36,3.384c-8.635-16.424-25.652-26.718-44.208-26.744H167.776l40.84-20.424
                            c3.724-1.859,5.401-6.263,3.856-10.128l-16.968-42.4c-1.641-4.102-6.296-6.098-10.398-4.458c-0.207,0.083-0.41,0.174-0.609,0.274
                            l-67.832,33.936c-3.721,1.862-5.394,6.265-3.848,10.128l13.232,33.072H84l-9.472-25.264c-6.715-17.738,1.91-37.598,19.456-44.8
                            l268.248-111.2c17.933-7.436,38.499,1.073,45.936,19.006c0.116,0.279,0.228,0.56,0.336,0.842l53.68,139.568
                            C468.975,196.285,460.276,216.333,442.568,223.598z"/>
                        <path d="M287.775,441.742c14.908,8.661,33.317,8.661,48.225,0c22.922,13.317,52.3,5.53,65.617-17.392s5.53-52.3-17.392-65.617
                            c-7.357-4.274-15.717-6.516-24.225-6.496c-8.434,0.004-16.716,2.245-24,6.496c-22.922-13.317-52.3-5.531-65.617,17.391
                            C257.066,399.047,264.853,428.424,287.775,441.742z M349.376,370.19c3.403-1.254,6.997-1.914,10.624-1.952
                            c17.673,0,32,14.327,32,32c0,17.673-14.327,32-32,32c-3.627-0.038-7.221-0.698-10.624-1.952
                            C363.54,412.757,363.54,387.718,349.376,370.19z M336,379.294c10.667,11.926,10.667,29.962,0,41.888
                            C325.333,409.256,325.333,391.219,336,379.294z M312,368.238c3.627,0.037,7.221,0.698,10.624,1.952
                            c-14.164,17.528-14.164,42.568,0,60.096c-3.403,1.254-6.997,1.914-10.624,1.952c-17.673,0-32-14.327-32-32
                            C280,382.564,294.327,368.238,312,368.238z"/>
                        <rect x="370.883" y="113.937" transform="matrix(-0.4062 -0.9138 0.9138 -0.4062 390.4948 565.1589)" width="16" height="83.52" />
                        <rect x="284.256" y="122.262" transform="matrix(0.908 -0.4191 0.4191 0.908 -22.8353 156.5479)" width="121.408" height="15.992" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const SVGSuccess = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 408.576 408.576" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <g>
                <g>
                    <path d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288
                        S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6
                        c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344
                        c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"/>
                </g>
            </g>
        </svg>
    );
};

export const SVGError = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 51.976 51.976" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <g>
                <path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0
                    C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778
                    c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828
                    c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828
                    l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z"/>
            </g>
        </svg>
    );
};

export const SVGSearch = ({ style, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.966 56.966" style={style} {...rest}>
            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
                s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
                c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
                s-17-7.626-17-17S14.61,6,23.984,6z"/>
        </svg>
    );
};

export const SVGOrder = ({ style, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style} {...rest}>
            <g>
                <g>
                    <g>
                        <path d="M176.583,188.948c-32.604,15.896-59.458,40.927-77.646,72.375c-2.958,5.104-1.208,11.625,3.885,14.573
                            c1.688,0.979,3.521,1.438,5.333,1.438c3.688,0,7.271-1.906,9.24-5.323c16.063-27.76,39.771-49.854,68.542-63.896
                            c5.292-2.583,7.49-8.969,4.906-14.26C188.281,188.563,181.917,186.344,176.583,188.948z"/>
                        <path d="M501.333,341.333H479.46c-5.158-108.708-87.964-197.228-194.176-211.193c1.725-3.928,2.716-8.249,2.716-12.807
                            c0-17.646-14.354-32-32-32s-32,14.354-32,32c0,4.559,0.991,8.879,2.716,12.807C120.504,144.105,37.698,232.625,32.54,341.333
                            H10.667C4.771,341.333,0,346.104,0,352v10.688c0,16.052,4.708,31.604,13.625,44.979c7.927,11.896,21.198,19,35.5,19h413.75
                            c14.292,0,27.573-7.104,35.51-19.01C507.292,394.281,512,378.74,512,362.688V352C512,346.104,507.229,341.333,501.333,341.333z
                            M256,106.667c5.885,0,10.667,4.781,10.667,10.667c0,5.885-4.781,10.667-10.667,10.667s-10.667-4.781-10.667-10.667
                            C245.333,111.448,250.115,106.667,256,106.667z M256,149.333c108.15,0,196.526,85.22,202.126,192H53.874
                            C59.474,234.553,147.85,149.333,256,149.333z M490.667,362.688c0,11.823-3.469,23.292-10.042,33.146
                            c-3.969,5.948-10.604,9.5-17.75,9.5H49.125c-7.146,0-13.792-3.552-17.76-9.51c-6.563-9.854-10.031-21.313-10.031-33.135v-0.021
                            h469.333V362.688z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const SVGLeftArrow = ({ style, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823" style={style} {...rest}>
            <g>
                <path d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
                    l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
                    C52.942,116.507,52.942,124.327,57.633,129.007z"/>
            </g>
        </svg>
    );
};

export const SVGEdit = ({ style, ...rest }) => {
    return (
        <svg height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 
                30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 
                30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 
                22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 
                50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/>
            <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 
                1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 
                2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 
                4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 
                146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 
                14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 
                25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/>
        </svg>
    );
};

export const SVGBurguer = ({ style, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style} {...rest}>
            <g>
                <g>
                    <path d="M497,331h-30c8.284,0,15-6.716,15-15c0-8.286-6.716-15-15-15H45c-8.284,0-15,6.714-15,15c0,8.284,6.716,15,15,15H15
                        c-8.291,0-15,6.709-15,15c0,8.291,6.709,15,15,15h30c-8.284,0-15,6.714-15,15c0,8.284,6.716,15,15,15h422c8.284,0,15-6.716,15-15
                        c0-8.286-6.716-15-15-15h30c8.291,0,15-6.709,15-15C512,337.709,505.291,331,497,331z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M256,0C115.39,0,0,114.39,0,255c0,8.287,6.716,15,15,15h482c8.284,0,15-6.714,15-15C512,114.39,396.61,0,256,0z M136,150
                        c-8.284,0-15-6.716-15-15c0-8.286,6.716-15,15-15s15,6.714,15,15C151,143.284,144.284,150,136,150z M196,210
                        c-8.284,0-15-6.716-15-15c0-8.286,6.716-15,15-15s15,6.714,15,15C211,203.284,204.284,210,196,210z M196,90
                        c-8.284,0-15-6.716-15-15s6.716-15,15-15s15,6.716,15,15S204.284,90,196,90z M256,150c-8.284,0-15-6.716-15-15
                        c0-8.286,6.716-15,15-15s15,6.714,15,15C271,143.284,264.284,150,256,150z M316,210c-8.284,0-15-6.716-15-15
                        c0-8.286,6.716-15,15-15s15,6.714,15,15C331,203.284,324.284,210,316,210z M316,90c-8.284,0-15-6.716-15-15s6.716-15,15-15
                        s15,6.716,15,15S324.284,90,316,90z M376,150c-8.284,0-15-6.716-15-15c0-8.286,6.716-15,15-15s15,6.714,15,15
                        C391,143.284,384.284,150,376,150z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M497,422H15c-8.284,0-15,6.713-15,15c0,41.353,33.647,75,75,75h362c41.353,0,75-33.647,75-75
                        C512,428.713,505.284,422,497,422z"/>
                </g>
            </g>
        </svg>
    );
};

export const SVGBeer = ({ style, ...rest }) => {
    return (
        <svg viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <g>
                <path d="m393.421 186.017h-15.918v63.924h5.264c17.652 0 31.962 14.31 31.962 31.962v71.008c0 17.652-14.31 31.962-31.962 
                    31.962h-5.264v63.924h15.918c47.073 0 85.232-38.16 85.232-85.232v-92.316c.001-47.072-38.159-85.232-85.232-85.232z"/>
                <path d="m312.503 21.684c-12.014-.71-23.297 2.21-32.863 7.765-8.647 5.021-19.734 2.957-26.165-4.7-12.711-15.131-31.771-24.749-53.078-24.749-21.308 
                    0-40.368 9.618-53.078 24.75-6.431 7.656-17.517 9.72-26.163 4.699-9.565-5.554-20.848-8.475-32.861-7.765-29.653 1.752-53.524 25.963-54.885 
                    55.637-.824 17.963 6.462 34.239 18.501 45.493h65.304v84.449c0 17.652 14.31 31.962 31.962 31.962 17.652 0 31.962-14.31 
                    31.962-31.962v-84.449h167.743c12.039-11.254 19.325-27.528 18.502-45.49-1.359-29.673-25.228-53.885-54.881-55.64z"/>
                <path d="m211.14 207.263c0 34.166-27.796 61.962-61.962 61.962s-61.962-27.796-61.962-61.962v-54.448h-33.925v327.223c0 17.652 
                    14.31 31.962 31.962 31.962h230.288c17.652 0 31.962-14.31 31.962-31.962v-327.223h-136.363z"/>
            </g>
        </svg>
    );
};

export const SVGHandcuff = ({ style, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style} {...rest}>
            <g>
                <g>
                    <path d="M501.262,86.489c-14.315-34.561-41.234-61.479-75.795-75.794C408.337,3.6,390.326,0.002,371.934,0.002
                        c-34.546,0-67.412,12.53-93.122,35.392L209.858,6.832l-16.897,40.792c-41.341-17.017-88.982-7.756-121.057,24.32
                        c-32.075,32.075-41.337,79.716-24.32,121.057L6.792,209.898l28.563,68.956c-35.527,39.993-45.276,96.977-24.7,146.655
                        c21.761,52.536,72.569,86.485,129.44,86.49c0.003,0,0.006,0,0.009,0c18.389,0,36.401-3.598,53.534-10.695
                        c34.561-14.315,61.48-41.234,75.795-75.794c14.315-34.561,14.315-72.629,0-107.19c-20.577-49.677-67.765-83.078-121.166-86.235
                        l-28.562-68.956l-43.156,17.876c-12.173-29.68-5.505-63.864,17.521-86.891c23.027-23.027,57.211-29.695,86.891-17.522
                        l-17.876,43.155l68.956,28.562c3.158,53.401,36.558,100.589,86.235,121.166c17.133,7.097,35.147,10.695,53.543,10.695
                        c0.003,0,0,0,0.003,0c56.865,0,107.676-33.951,129.438-86.49C515.579,159.118,515.579,121.051,501.262,86.489z M81.163,229.76
                        c7.542-3.123,16.188,0.458,19.312,8c3.123,7.542-0.458,16.188-8,19.312c-7.542,3.123-16.188-0.458-19.312-8
                        C70.04,241.53,73.621,232.884,81.163,229.76z M111.754,303.614c9.016-3.735,18.499-5.628,28.185-5.628
                        c30.072,0,56.922,17.913,68.406,45.637c7.544,18.211,7.531,38.299-0.036,56.566c-7.567,18.266-21.763,32.481-39.973,40.024
                        c-9.016,3.735-18.499,5.628-28.185,5.628c-30.072,0-56.922-17.913-68.406-45.636C56.146,362.544,74.095,319.214,111.754,303.614z
                        M257.032,92.516c-3.123,7.542-11.77,11.123-19.312,7.999c-7.542-3.123-11.123-11.77-7.999-19.312
                        c3.123-7.542,11.77-11.123,19.312-7.999C256.574,76.328,260.155,84.974,257.032,92.516z M440.173,168.375
                        c-11.484,27.724-38.335,45.637-68.406,45.637c-9.686,0-19.168-1.894-28.184-5.628c-37.661-15.599-55.609-58.93-40.009-96.591
                        c11.484-27.723,38.334-45.636,68.406-45.636c9.686,0,19.168,1.894,28.185,5.628C437.824,87.385,455.772,130.715,440.173,168.375z"
                        />
                </g>
            </g>
        </svg>
    );
};

SVGMinus.propTypes, SVGPlus.propTypes, SVGClose.propTypes, SVGCash.propTypes,
SVGCard.propTypes, SVGSuccess.propTypes, SVGError.propTypes, SVGSearch.propTypes,
SVGOrder.propTypes, SVGLeftArrow.propTypes, SVGEdit.propTypes, SVGBurguer.propTypes,
SVGBeer.propTypes, SVGHandcuff.propTypes = {
    style: PropTypes.object,
}