import * as React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../assets/styles/themes/Theme';
import styles from './styles';
import { Button, Text, View } from '../';

const Section = ({
    children,
    title,
    actionButtonText,
    onButtonActionClicked,
    style,
    ...rest
}) => {

    return (
        <View style={style} {...rest}>
            <View style={styles.sectionTitleContainer}>
                {title && <Text style={styles.sectionTitle}>{title}</Text>}
                {actionButtonText && (
                    <Button onClick={onButtonActionClicked && onButtonActionClicked} isTransparent>
                        <Text style={{...styles.actionButtonText, ...{color: theme.colors.primary} }}>
                            {actionButtonText}
                        </Text>
                    </Button>
                )}
            </View>
            {children}
        </View>
    );
};

Section.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    actionButtonText: PropTypes.string,
    onButtonActionClicked: PropTypes.func,
    style: PropTypes.object
}

export default Section;
