import * as React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Text, View } from '../../../elements';
import { formatCurrency } from '../../../../utils/numberFormater';
import styles from './styles';

const CartSummary = ({ products, style }) => {

    if (!products || !(products.length > 0)) return (<></>);

    var totalPrice = 0;
    var totalProducts = 0;
    products.forEach(product => {
        totalProducts += product.node.qty;
        totalPrice += product.node.qty * parseFloat(product.node.product.price);
    });

    return (
        <Container 
            style={{
            ...styles.viewCartButtonContainer,
            ...style
            }}>
            <Button
                style={styles.viewCartButton}
                childrenContainerStyle={styles.viewCartButtonChildren}
                onClick={() => window.location.href = "/checkout"}>
                <View style={styles.viewCartButtonTextContainer}>
                    <Text isBold style={styles.viewCartButtonText}>
                        Ver carrito
                    </Text>
                    <Text style={styles.cardItemText}>
                        {`${totalProducts} ${totalProducts > 1 ? 'productos' : 'producto'}`}
                    </Text>
                </View>
                <Text style={styles.totalPriceText} isBold>
                    {formatCurrency(totalPrice)}
                </Text>
            </Button>
        </Container>
    );
};

CartSummary.propTypes = {
    products: PropTypes.array,
    style: PropTypes.object,
}

export default CartSummary;
