import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, Container, Divider, Text, View } from '../';
import { SVGLeftArrow } from '../../../assets/resources/sixtynitePlay/icons/icons';

const Dialog = ({
    isVisible,
    title,
    isFullSize,
    isTitleCentered,
    contentStyle,
    footer,
    onClose,
    children,
    ...rest
}) => {

    return (
        isVisible ? (
            <Container
                style={styles.container}
                {...rest}>
                {isFullSize && (
                    <Button style={styles.closeButton} isTransparent onClick={onClose && onClose}>
                        <SVGLeftArrow height="40" width="20" style={styles.closeButtonIcon}/>
                    </Button>
                )}
                <View 
                    style={{
                            ...isFullSize ? styles.fsview : styles.view,
                            ...contentStyle
                        }}>
                    {title && (
                    <>
                        <Text
                            isBold
                            isHeadingTitle
                            isPrimary
                            isCenter={isTitleCentered}
                            style={styles.title}>
                            {title}
                        </Text>
                        <Divider />
                    </>
                    )}
                    <View>{children}</View>
                    {footer && footer}
                </View>
            </Container>
        ) : (
            null
        )
    );
};

Dialog.propTypes = {
    isVisible: PropTypes.bool,
    title: PropTypes.string,
    isFullSize: PropTypes.bool,
    isTitleCentered: PropTypes.bool,
    contentStyle: PropTypes.object,
    footer: PropTypes.node,
    onClose: PropTypes.func,
    children: PropTypes.node
}

Dialog.defaultProps = {
    isVisible: false,
}

export default Dialog;

